import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import { OrthographicCamera } from 'three/src/cameras/OrthographicCamera';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.initShader();
        this.initWebGL();
        this.initMesh();
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.isAdded = false;
        this.isHover = false;
        this.groupLen = 5;
        this.itemLenForOneGroup = 10;
        this.itemLenForOneGroupH = this.itemLenForOneGroup * .5;

        this.positionList;
        this.scale;
        this.oneGroupSize;
        this.adjustX, this.adjustY;
        this.moveAreaY;
        this.moveSpeed = .9;
    }

    setDom(){
        super.setDom();

        this.canvas = document.createElement("canvas");
    }

    initEvents(){
        super.initEvents();
        this.bindMouseOverHandler = this.mouseOverHandler.bind(this);
        this.bindMouseOutHandler = this.mouseOutHandler.bind(this);
    }

    mouseOverHandler(event){
        this.isHover = true;
    }

    mouseOutHandler(event){
        this.isHover = false;
    }

    getDom(){
        this.container = document.querySelector('.next .canvasContainer');
        this.to_contact = document.querySelector('.next .to_contact');
    }

    initShader() {
        this.vertexShaderSrc = `
            attribute float scale;
            uniform float size;
            
            void main() {
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);                
                gl_PointSize = size * scale;
                gl_Position = projectionMatrix * mvPosition;
            }
        `;

        this.fragmentShaderSrc = `
            uniform sampler2D tex;
                        
            void main() {
                if(texture2D(tex, gl_PointCoord).a < .5) gl_FragColor = vec4(1., 1., 1., 0.);       //alphaTest
                else gl_FragColor = vec4(1., 1., 1., .3) * texture2D(tex, gl_PointCoord);
            }
        `;
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : false,
            antialias : false
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = false;

        this.scene = new THREE.Scene();
        // this.scene.background = new THREE.Color( 0xE8FAFE );

        //Camera
        this.camera = new OrthographicCamera(-this.swh, this.swh, this.shh, -this.shh, 0.1, 10000);
        this.camera.position.set(0, 0, 10);
    }

    initMesh(){
        /*
        // for debug
        let geometry = new THREE.PlaneGeometry( 200, 200, 4 );
        var material = new THREE.MeshBasicMaterial( {color: 0xff0000, opacity:1, side: THREE.DoubleSide} );
        this.plane = new THREE.Mesh( geometry, material );
        this.scene.add( this.plane );
        */

        const texture = new THREE.TextureLoader().load( '/assets/img/common/kadomaru_next128.png' );

        this.setVariable();
        this.resizeMesh();

        this.material = new THREE.ShaderMaterial({
            uniforms:{
                size: {
                    type: 'f',
                    value: this.size
                },
                tex: {
                    type: 't',
                    value: texture
                }
            },
            vertexShader: this.vertexShaderSrc,
            fragmentShader: this.fragmentShaderSrc,
            transparent: true,
        });

        // 形状データを作成
        this.geometry = new THREE.BufferGeometry();
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));

        // 物体を作成
        const mesh = new THREE.Points(this.geometry, this.material);
        this.scene.add(mesh);
    }

    add(){
        this.getDom();
        this.container.appendChild(this.canvas);
        this.isAdded = true;

        if(!this.pack.hasTouch){
            this.to_contact.addEventListener("mouseover", this.bindMouseOverHandler);
            this.to_contact.addEventListener("mouseout", this.bindMouseOutHandler);
        }
    }

    remove(){
        this.canvas.remove();
        this.isAdded = false;

        if(!this.pack.hasTouch){
            this.to_contact.removeEventListener("mouseover", this.bindMouseOverHandler);
            this.to_contact.removeEventListener("mouseout", this.bindMouseOutHandler);
        }
    }

    start(){

    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        this.vertices = [];
        this.scales = [];

        if(this.isHover){
            for( let h = 0; h < this.groupLen; h++ ){
                let scale;

                if(h === 2) scale = 1;
                else scale = 0;

                for( let i = 0; i < this.itemLenForOneGroup; i++ ){
                    let p = this.positionHoverList[i];
                    const x = p.x + this.adjustHoverX;
                    const y = p.y;
                    const z = 0;
                    if(h === 2 && i > 8) scale = 0;

                    let easeP = this.easeVertices[h][i];
                    if(scale === 1){
                        let ease = p.ease;
                        easeP.x += (x - easeP.x) * ease;
                        easeP.y += (y - easeP.y) * ease;
                    }

                    let easeS = this.easeScales[h][i];
                    easeS.scale += (scale - easeS.scale) * .3;

                    this.vertices.push(easeP.x, easeP.y, z);
                    this.scales.push(easeS.scale);
                }
            }
        }else{
            for( let h = 0; h < this.groupLen; h++ ){
                for( let i = 0; i < this.itemLenForOneGroup; i++ ){
                    let p = this.positionList[i];
                    if(i < this.itemLenForOneGroupH) p.deg += this.moveSpeed;
                    else p.deg -= this.moveSpeed;

                    const x = p.x + this.oneGroupSize * h - this.adjustX;
                    const y = Math.sin(this.pack.d2r(p.deg)) * this.moveAreaY + this.adjustY;
                    const z = 0;
                    const scale = p.scale;

                    let easeP = this.easeVertices[h][i];
                    easeP.x += (x - easeP.x) * .3;
                    easeP.y += (y - easeP.y) * .3;


                    let easeS = this.easeScales[h][i];
                    easeS.scale += (scale - easeS.scale) * .3;

                    this.vertices.push(easeP.x, easeP.y, z);
                    this.scales.push(easeS.scale);
                }
            }
        }

        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));
        this.geometry.setAttribute('scale', new THREE.Float32BufferAttribute(this.scales, 1));

        if(this.isAdded) this.render();
    }

    resizeMesh(){
        this.vertices = [];    //// 頂点情報を格納する配列
        this.scales = [];
        this.easeVertices = [];
        this.easeScales = [];

        this.scale = this.sw / this.baseW;
        this.size = this.baseSize * this.scale;
        this.oneGroupSize = (this.size * 5);
        this.adjustX = (this.size * 5) * 2;
        this.adjustY = this.baseY * this.scale;
        this.adjustHoverX = this.baseHoverX * this.scale;
        let singleDeg = 360 / this.itemLenForOneGroup;
        this.moveAreaY = this.size * 2.5;

        this.positionList = [
            {x: -this.size * 2, y: this.size, deg: 0, scale:1},
            {x: -this.size * 1, y: this.size * 2, deg: singleDeg * 1, scale:1},
            {x: 0, y: this.size * 3, deg: singleDeg * 2, scale:1},
            {x: this.size * 1, y: this.size * 2, deg: singleDeg * 3, scale:1},
            {x: this.size * 2, y: this.size * 1, deg: singleDeg * 4, scale:1},
            {x: this.size * 2, y: 0, deg: -singleDeg * 4, scale:1},
            {x: this.size * 1, y: -this.size * 1, deg: -singleDeg * 3, scale:1},
            {x: 0, y: -this.size * 2, deg: -singleDeg * 2, scale:1},
            {x: -this.size * 1, y: -this.size * 1, deg: -singleDeg * 1, scale:1},
            {x: -this.size * 2, y: 0, deg: 0, scale:1},
        ];

        this.positionHoverList = [
            {x: -this.size * 3, y: 0, ease:.25},
            {x: -this.size * 2, y: 0, ease:.5},
            {x: -this.size * 1, y: 0, ease:.35},
            {x: 0, y: this.size * 2, ease:.25},
            {x: 0, y: 0, ease:.8},
            {x: 0, y: -this.size * 2, ease:.45},
            {x: this.size * 1, y: this.size * 1, ease:.5},
            {x: this.size * 1, y: -this.size * 1, ease:.35},
            {x: this.size * 2, y: 0, ease:.45},
            {x: 0, y: 0, ease:.2},
        ];

        for( let h = 0; h < this.groupLen; h++ ){
            this.easeVertices[h] = [];
            this.easeScales[h] = [];
            for( let i = 0; i < this.itemLenForOneGroup; i++ ){
                let p = this.positionList[i];
                const x = p.x + this.oneGroupSize * h - this.adjustX;
                const y = this.adjustY;
                const z = 0;
                const scale = p.scale;
                this.easeVertices[h][i] = {x:x,y:y,z:z};
                this.easeScales[h][i] = {scale:p.scale};
                this.vertices.push(x, y, z);
                this.scales.push(scale);
            }
        }
    }

    setVariable(){
        if(this.sw >= this.pack.BP){
            this.baseSize = 80;
            this.baseW = 1440;
            this.baseH = 789;
            this.baseY = 6;
            this.baseHoverX = 40;
        }else{
            this.baseSize = 40;
            this.baseW = 375;
            this.baseH = 627;
            this.baseY = -2;
            this.baseHoverX = 20;
        }
    }


    executeResize() {
        super.executeResize();

        if(!this.canvas) return;

        if(this.sw < this.pack.BP) {
            this.sh = this.sh * .604;
            this.shh = this.sh * .5;
        }

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;
        if(!this.camera) return;
        this.camera.left = -this.swh;
        this.camera.right = this.swh;
        this.camera.top = this.shh;
        this.camera.bottom = -this.shh;

        this.camera.aspect = this.sw / this.sh;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);

        this.setVariable();
        this.resizeMesh();

        this.material.uniforms.size.value = this.size;
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));
    }
}