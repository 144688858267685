import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import { OrthographicCamera } from 'three/src/cameras/OrthographicCamera';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.initShader();
        this.initWebGL();
        this.initMesh();
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.scales = [];
        this.baseScale = 0;
    }

    setDom(){
        super.setDom();
        this.loading = document.querySelector('#loading');
        this.canvas = document.createElement("canvas");
        this.loadingCavasContainer = document.querySelector('#loading .canvasContainer');
        this.loadingCavasContainer.appendChild(this.canvas);
        this.loadingText = document.querySelector('#loading .text');
        this.loadingImg = document.querySelector('#loading img');
    }

    initEvents(){
        super.initEvents();
    }

    initShader() {
        this.vertexShaderSrc = `
            attribute float scale;
            uniform float size;
            
            void main() {
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);                
                gl_PointSize = size * scale;
                gl_Position = projectionMatrix * mvPosition;
            }
        `;

        this.fragmentShaderSrc = `
            uniform sampler2D tex;
                        
            void main() {
                if(texture2D(tex, gl_PointCoord).a < .75) gl_FragColor = vec4(1., 1., 1., 0.);       //alphaTest
                else gl_FragColor = vec4(1., 1., 1., 1.) * texture2D(tex, gl_PointCoord);
            }
        `;
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : false,
            antialias : false
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = false;


        this.scene = new THREE.Scene();
        // this.scene.background = new THREE.Color( 0xE8FAFE );


        //Camera
        this.camera = new OrthographicCamera(-this.swh, this.swh, this.shh, -this.shh, 0.1, 10000);
        this.camera.position.set(0, 0, 10);

    }

    initMesh(){
        /*
        // for debug
        let geometry = new THREE.PlaneGeometry( 200, 200, 4 );
        var material = new THREE.MeshBasicMaterial( {color: 0xff0000, opacity:1, side: THREE.DoubleSide} );
        this.plane = new THREE.Mesh( geometry, material );
        this.scene.add( this.plane );
        */

        const texture = new THREE.TextureLoader().load( '/assets/img/common/kadomaru64.png' );

        this.setVariable();
        this.resizeMesh();
        this.setCntsForScale();

        this.material = new THREE.ShaderMaterial({
            uniforms:{
                size: {
                    type: 'f',
                    value: this.size
                },
                tex: {
                    type: 't',
                    value: texture
                }
            },
            vertexShader: this.vertexShaderSrc,
            fragmentShader: this.fragmentShaderSrc,
            transparent: true,
        });

        // 形状データを作成
        this.geometry = new THREE.BufferGeometry();
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));

        // 物体を作成
        const mesh = new THREE.Points(this.geometry, this.material);
        this.scene.add(mesh);
    }

    start(){

    }

    completeLoading(scope, callback, callback2){
        TweenMax.to(this, 2, {baseScale: 2.2, ease:Linear.easeOut, onComplete:()=>{
            this.hideLoading();
            callback.apply(scope);
        }});

        TweenMax.delayedCall(4.0, ()=>{
            callback2.apply(scope);
        });
    }

    hideLoading(){
        this.isCompleteLoading = true;

        //サイズの不揃い
        for( let i = 0, len = this.scales.length; i < len; i++ ){
            this.scales[i] = 2.2 * (1 + Math.floor(Math.random() * 5));
        }
        this.loading.classList.add("hide");
    }

    scrollHandler(){

    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    enterframeThinOut(){
        if(this.isCompleteLoading){
            for( let i = 0; i < this.scales.length; i++ ){
                let scale = this.scales[i];
                scale += (0 - scale) / 10;
                this.scales[i] = scale;
            }
        }else{
            for( let i = 0; i < this.cntsForScale.length; i++ ){
                this.cntsForScale[i] += 11;
                let rad = this.pack.d2r(this.cntsForScale[i]);
                let sin = this.baseScale + (Math.sin(rad) + 1) * .5;
                this.scales[i] = sin;
            }
        }

        this.geometry.setAttribute('scale', new THREE.Float32BufferAttribute(this.scales, 1));
        // this.material.uniforms.time.value += 3;
        this.render();
    }

    resizeMesh(){
        this.vertices = [];    //// 頂点情報を格納する配列
        let baseRatio = this.baseW / this.baseH;
        let stageRatio = this.sw / this.sh;
        let scale;
        if(stageRatio >= baseRatio) scale = this.sw / this.baseW;
        else scale = this.sh / this.baseH;

        this.size = this.baseSize * scale;
        this.gridSize = this.baseGridSize * scale;
        this.gridSizeH = this.gridSize / 2;

        let adjustX = (this.lenX / 2) * this.gridSize;
        let adjustY = (this.lenY / 2) * this.gridSize;
        for( let i = 0; i < this.lenX; i++ ){
            for( let j = 0; j < this.lenY; j++ ){
                const x = this.gridSize * i + this.gridSizeH - adjustX;
                const y = this.gridSize * j + this.gridSizeH - adjustY;
                const z = 0;
                this.vertices.push(x, y, z);
            }
        }
    }

    setVariable(){
        if(this.sw >= this.pack.BP){
            this.baseSize = 30;
            this.baseGridSize = 40;
            this.baseW = 1440;
            this.baseH = 789;
            this.lenX = 36;
            this.lenY = 20;
        }else{
            this.baseSize = 15;
            this.baseGridSize = 21;
            this.baseW = 375;
            this.baseH = 627;
            this.lenX = 18;
            this.lenY = 30;
        }
    }

    setCntsForScale(){
        this.cntsForScale = [];
        for( let i = 0; i < this.lenX; i++ ){
            for( let j = 0; j < this.lenY; j++ ){
                this.cntsForScale.push(-i * 10 - j * 10);
            }
        }
    }

    executeResize() {
        super.executeResize();

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;
        this.camera.left = -this.swh;
        this.camera.right = this.swh;
        this.camera.top = this.shh;
        this.camera.bottom = -this.shh;

        this.camera.aspect = this.sw / this.sh;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);

        this.loading.style.height = this.sh + 'px';
        this.loadingImg.style.top = this.shh + "px";

        this.setVariable();
        this.resizeMesh();
        this.setCntsForScale();
        this.material.uniforms.size.value = this.size;
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));
    }
}
