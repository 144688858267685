import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.isMenuOpen = false;

        this.baseGrillRight = 100;
        this.baseHeaderNavRight = 119;
        this.baseHeaderLangNavRight = 120;
    }

    setDom(){
        super.setDom();

        this.grill = document.querySelector('header .grill');
        this.menu = document.querySelector('header .menu');
        this.menuBG = document.querySelector('header .menu .bg');

        this.h2 = document.querySelector('header h2');
        this.headerNav = document.querySelector('header .header_nav');
        this.headerNavList = document.querySelectorAll('header .header_nav ul li');

        this.menuMainNav = document.querySelectorAll('header .menu .main_nav li');
        this.menuMainNavTxt = document.querySelectorAll('header .menu .main_nav .main');

        this.menuMainNavSubContainer = document.querySelectorAll('header .menu .main_nav li span.sub');
        this.menuMainNavSubTxt = [];

        this.menuLangNav = document.querySelector('header .menu .lang_nav');
        this.menuLangNavs = document.querySelectorAll('header .menu .lang_nav ul li span');
        if(this.pack.LANG === 'ja') {
            this.menuLangNavs[0].classList.add('current');
        }else{
            this.menuLangNavs[1].classList.add('current');
        }

        this.menuNewsletter = document.querySelector('header .menu .newsletter');

        this.menuSubNavSocials = document.querySelectorAll('header .menu .sub_nav .social li');
        this.menuSubNavOthers = document.querySelectorAll('header .menu .sub_nav .others li');
        this.menuCopyright = document.querySelector('header .menu .cp');
    }

    initEvents(){
        super.initEvents();

        //アンカーリンクの設定
        for( let i = 0, len = this.menuMainNavSubTxt.length; i < len; i++ ){
            let items = this.menuMainNavSubTxt[i];
            for( let j = 0; j < items.length; j++ ){
                let item = items[j];
                item.addEventListener("click", (event)=>{
                    let url = location.protocol + '//' + location.host + location.pathname;
                    let extract_hash = event.currentTarget.href.replace(/#.*$/,"");

                    if (extract_hash === url ){
                        this.closeMenu();
                    }
                });
            }
        }

        this.grill.addEventListener("click", this.clickGrillHandler.bind(this));
        this.grill.addEventListener("mouseover", (event)=>{
            this.grill.classList.add("hover");
        });

        this.grill.addEventListener("mouseout", (event)=>{
            this.grill.classList.remove("hover");
        });

        this.menuLangNavs[0].addEventListener("click", ()=>{
            this.pack.setLocalStorage("jp");
            this.pack.changeLang('jp');
        });

        this.menuLangNavs[1].addEventListener("click", ()=>{
            this.pack.setLocalStorage("en");
            this.pack.changeLang('en');
        });
    }

    setEvents(){

    }

    getAnchorSrc(id, pathname, str){
        let spanSrc = '';
        let anchorName = 'section' + (id + 1);

        for( let i = 0, len = str.length; i < len; i++ ){
            let value = str.substr(i, 1);

            if(value === " ") spanSrc += '<span class="item space">';
            else spanSrc += '<span class="item">';
            spanSrc += value;
            spanSrc += '</span>';
        }

        return `
            <a class="white" href="/${pathname}#${anchorName}">
                ${spanSrc}
            </a>
        `;
    }

    setAnchors(){
        this.setServicesAnchors();

        for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
            this.menuMainNavSubTxt.push(this.menuMainNav[i].querySelectorAll('.sub a'));
        }
    }

    setServicesAnchors(){
        let items = this.pack.apiJSONList[2].items;
        let src = '';
        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i];
            let name = this.pack.getData(item.fields, "areaTitle");
            name = name.replace(/<br>/g, '');  //全ての<br>を''に置き換える
            src += this.getAnchorSrc(i, "services", name);
        }
        this.menuMainNavSubContainer[0].innerHTML = src;
    }

    clickGrillHandler(){
        if(this.menu.classList.contains("show")){
            this.closeMenu();
        }else{
            this.openMenu();
        }
    }

    closeMenu() {
        this.isMenuOpen = false;
        document.documentElement.classList.remove("lock");
        document.documentElement.style.setProperty('--grill_right',this.baseGrillRight + 'px');
        document.documentElement.style.setProperty('--header_nav_right',this.baseHeaderNavRight + 'px');
        this.grill.classList.remove("close");
        this.menuBG.classList.remove("open");
        this.menu.classList.remove("open");

        for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
            let item = this.menuMainNav[i];
            item.style.transitionDelay = "0s";
            item.classList.add("close");
        }

        for( let i = 0, len = this.menuMainNavSubTxt.length; i < len; i++ ){
            let items = this.menuMainNavSubTxt[i];
            for( let j = 0; j < items.length; j++ ){
                let item = items[j];
                item.style.transitionDelay = "0s";
                item.classList.remove("open");
            }
        }

        this.menuLangNav.style.transitionDelay = "0s";
        this.menuLangNav.classList.remove("open");

        this.menuNewsletter.style.transitionDelay = "0s";
        this.menuNewsletter.classList.remove("open");

        for( let i = 0, len = this.menuSubNavSocials.length; i < len; i++ ){
            let item = this.menuSubNavSocials[i];
            item.style.transitionDelay = "0s";
            item.classList.remove("open");
        }

        for( let i = 0, len = this.menuSubNavOthers.length; i < len; i++ ){
            let item = this.menuSubNavOthers[i];
            item.style.transitionDelay = "0s";
            item.classList.remove("open");
        }

        this.menuCopyright.style.transitionDelay = "0s";
        this.menuCopyright.classList.remove("open");

        TweenMax.delayedCall(.7, ()=>{
            this.menu.classList.remove("show");
            for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
                let item = this.menuMainNav[i];
                item.style.transitionDelay = "0s";
                item.classList.remove("close");
                item.classList.remove("open");
            }

            for( let i = 0, len = this.menuMainNavTxt.length; i < len; i++ ){
                let item = this.menuMainNavTxt[i];
                item.style.transitionDelay = "0s";
                item.classList.remove("open");
            }
        });
    }

    openMenu() {
        this.isMenuOpen = true;
        let scrollWidth = window.innerWidth - document.body.clientWidth;
        document.documentElement.classList.add("lock");
        this.menu.classList.add("show");
        let grillRight = this.baseGrillRight + scrollWidth + 'px';
        let headerNavRight = this.baseHeaderNavRight + scrollWidth + 'px';
        let headerLangNavRight = this.baseHeaderLangNavRight + scrollWidth + 'px';

        document.documentElement.style.setProperty('--grill_right', grillRight);
        document.documentElement.style.setProperty('--header_nav_right', headerNavRight);
        document.documentElement.style.setProperty('--header_lang_nav_right', headerLangNavRight);
        TweenMax.delayedCall(0.1, ()=>{
            this.grill.classList.add("close");
            this.menuBG.classList.add("open");
            this.menu.classList.add("open");

            let delay = .12;

            for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
                let item = this.menuMainNav[i];
                item.style.transitionDelay = (i + 1) * delay + "s";
                item.classList.add("open");
            }

            for( let i = 0, len = this.menuMainNavTxt.length; i < len; i++ ){
                let item = this.menuMainNavTxt[i];
                // item.style.transitionDelay = .15 + i * delay + "s";
                item.style.transitionDelay = .15 + i * delay + "s";
                item.classList.add("open");
            }

            for( let i = 0, len = this.menuMainNavSubTxt.length; i < len; i++ ){
                let items = this.menuMainNavSubTxt[i];
                for( let j = 0; j < items.length; j++ ){
                    let item = items[j];
                    item.style.transitionDelay = .35 + i * delay + j * delay + "s";
                    // item.style.transitionDelay = .2 + i * delay + j * delay + "s";
                    item.classList.add("open");
                }
            }

            this.menuLangNav.style.transitionDelay = "400ms";
            this.menuLangNav.classList.add("open");


            this.menuNewsletter.style.transitionDelay = "700ms";
            this.menuNewsletter.classList.add("open");

            for( let i = 0, len = this.menuSubNavSocials.length; i < len; i++ ){
                let item = this.menuSubNavSocials[i];
                item.style.transitionDelay = .75 + i * .02 + "s";
                item.classList.add("open");
            }

            for( let i = 0, len = this.menuSubNavOthers.length; i < len; i++ ){
                let item = this.menuSubNavOthers[i];
                item.style.transitionDelay = .85 + i * .05 + "s";
                item.classList.add("open");
            }

            this.menuCopyright.style.transitionDelay = "1100ms";
            this.menuCopyright.classList.add("open");
        });
    }

    setCurrent(){
        let namespace = this.pack.current;
        let currentID, currentID2;

        for( let i = 0, len = this.menuMainNav.length; i < len; i++ ){
            let item = this.menuMainNav[i];
            item.classList.remove("current");
        }

        for( let i = 0, len = this.headerNavList.length; i < len; i++ ){
            let item = this.headerNavList[i];
            item.classList.remove("current");
        }

        if(namespace === 'top') currentID = currentID2 = -1;
        else if(namespace === 'services') currentID = currentID2 = 0;
        else if(namespace === 'reference_design') currentID = currentID2 = 1;
        else if(namespace === 'contact') {
            currentID = 2;
            currentID2 = 3;
        }else currentID = currentID2 = -1;

        this.menuMainNav[currentID + 1].classList.add("current");

        if(currentID2 === -1) return;
        this.headerNavList[currentID2].classList.add("current");
    }

    hideHeader(){
        this.h2.classList.add("hide");
        this.headerNav.classList.add("hide");
    }

    showHeader(){
        this.h2.classList.remove("hide");
        this.headerNav.classList.remove("hide");
    }

    showHeaderNav(){
        for( let i = 0, len = this.headerNavList.length; i < len; i++ ){
            let item = this.headerNavList[i];
            TweenMax.delayedCall(i * .12, ()=>{
                item.classList.add("show");
                item.classList.add("play");
            });
        }

        TweenMax.delayedCall(1, ()=>{
            for( let i = 0, len = this.headerNavList.length; i < len; i++ ){
                let item = this.headerNavList[i];
                item.classList.remove("play");
            }
        });
    }

    start(){

    }

    closeMenuExternal(){
        if(this.menu.classList.contains("show")){
            this.closeMenu();
        }
    }


    scrollHandler(){

    }

    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}
