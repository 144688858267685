import TemplateContents from './TemplateContents';
import Carousel from './Carousel';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';

        this.initEvents();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    setCarousel(){
        this.carousel.setTarget(this.section5);
        this.carousel.setMarginRightPC(true, 2.7777777777);
        this.carousel.setMarginRightSP(true, 5.3333333333);
        this.carousel.setAdjustMinXPC(true, this.pack.getPCVW(200));    　　//カルーセルの絶対値のX軸を設定
        this.carousel.setAdjustMinXSP(true, 5.25);                      //カルーセルの絶対値のX軸を設定
    }

    reset(){
        super.reset();
        this.setVars();
        // this.setCarousel();
        this.pack.hero.reset();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct(){

        super.destruct();
        this.lockScroll = false;
        this.enabledScroll = false;

        document.removeEventListener(this.mousewheelEvent, this.bindWheelHandler);
        document.removeEventListener('touchstart', this.bindTouchStartHandler);
        document.removeEventListener('touchmove', this.bindTouchMoveHandler);
        document.removeEventListener('touchend', this.bindTouchEndHandler);

        this.pack.hero.removeEventListener("openingEnded", this.bindOpeningEndedHeroHandler);
        this.pack.hero.removeEventListener("changedScene", this.bindChangedHeroHandler);

        this.heroSkip.removeEventListener("click", this.bindClickHeroSkipHandler);

        document.removeEventListener('mousemove', this.bindMouseMoveHandler);

        this.localNavLeftBt.removeEventListener("click", this.bindClickLocalNavLeftBtHandler);
        this.localNavRightBt.removeEventListener("click", this.bindClickLocalNavRightBtHandler);

        // this.carousel.destruct();

        this.pack.header.showHeader();

        this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.pack.hero.removeHero(this.heroCanvasContainer);
        this.pack.hero.destruct();
    }

    setVars(){
        super.setVars();

        this.loadedContentful = false;
        this.lockScroll = true;                     //スクロールを無効にするかどうか
        this.enabledScroll = false;
        this.currentHero = 1;
        this.pastHero = this.currentHero;
        this.HERO_SCENE_LEN = 8;

        this.currentSection = -1;

        this.touchStartPoint = 0;

        this.ourTeamList = [];
        this.ourTeamImgAccessAPIPathList = [];

        this.ourPartnerList = [];
        this.ourPartnerImgAccessAPIPathList = [];

        this.isWaitingBackHeroScene8 = false;

        this.mouseX = 0;
        this.mouseY = 0;
        this.onHeroContents6UL = false;
        this.onHeroContents7UL = false;
        this.heroContents6Current = 1;
        this.heroContents7Current = 1;

        this.prevWheelTime = Date.now();
        this.prevWheelDelta = 0;
    }

    setDom(){
        super.setDom();

        this.heroCanvasContainer = document.querySelector('main.top .hero .canvasContainer');
        this.heroContents = document.querySelectorAll('.hero .common_contents');
        this.heroScroll = document.querySelector('main.top .hero .scroll');
        this.heroCreateAccount = document.querySelector('main.top .hero .create_account');
        this.heroSkip = document.querySelector('main.top .hero .skip');

        this.heroCont3H3 = document.querySelector('main.top .hero .contents .contents3 h3');
        this.heroCont4H3 = document.querySelector('main.top .hero .contents .contents4 h3');
        this.heroCont5H3 = document.querySelector('main.top .hero .contents .contents5 h3');
        this.heroCont6H3 = document.querySelector('main.top .hero .contents .contents6 h3');
        this.heroCont7H3 = document.querySelector('main.top .hero .contents .contents7 h3');
        this.heroCont8H3 = document.querySelector('main.top .hero .contents .contents8 h3');

        this.heroCont3Txt = document.querySelector('main.top .hero .contents .contents3 .txt');
        this.heroCont4Txt = document.querySelector('main.top .hero .contents .contents4 .txt');
        this.heroCont5Txt = document.querySelector('main.top .hero .contents .contents5 .txt');
        this.heroCont6Txt = document.querySelector('main.top .hero .contents .contents6 .txt');
        this.heroCont6UL = document.querySelector('main.top .hero .contents .contents6 ul');
        this.heroCont7UL = document.querySelector('main.top .hero .contents .contents7 ul');
        this.heroCont8Txt = document.querySelector('main.top .hero .contents .contents8 .txt');
        this.heroCont8Chunk = document.querySelector('main.top .hero .contents .contents8 .chunk');

        this.heroCont3Btn = document.querySelector('main.top .hero .contents .contents3 .btn');
        this.heroCont4Btn = document.querySelector('main.top .hero .contents .contents4 .btn');
        this.heroCont5Btn = document.querySelector('main.top .hero .contents .contents5 .btn');
        this.heroCont6Btn = document.querySelector('main.top .hero .contents .contents6 .btn');
        this.heroCont7Btn = document.querySelector('main.top .hero .contents .contents7 .btn');
        this.heroCont8Btn = document.querySelector('main.top .hero .contents .contents8 .btn');
        this.heroCont9Btn = document.querySelector('main.top .hero .contents .contents9 .btn');

        this.heroContents6UL = document.querySelector('main.top .hero .contents .contents6 ul');
        this.heroContents7UL = document.querySelector('main.top .hero .contents .contents7 ul');

        this.localNav = document.querySelector('main.top .local_nav');
        this.localNavLabels = document.querySelectorAll('main.top .local_nav .labels .label');
        this.localNavLeftBt = document.querySelector('main.top .local_nav .left_bt');
        this.localNavRightBt = document.querySelector('main.top .local_nav .right_bt');
        this.localNavNumerator = document.querySelector('main.top .local_nav .current .numerator');

        this.sections = document.querySelectorAll('main.top .section');
        this.releaseNotesContainer = document.querySelector('main.top .section3 .documentation_update');
        this.section5 = document.querySelector('main.top .section5');
        this.ourTeamContainer = document.querySelector('main.top .section5 .items');
        this.ourPartnerContainer = document.querySelector('main.top .section4 .items');

        this.next = document.querySelector('.next');
    }

    initEvents(){
        super.initEvents();

        this.mousewheelEvent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';

        this.bindWheelHandler = this.wheelHandler.bind(this);
        this.bindTouchEndHandler = this.touchEndHandler.bind(this);
        this.bindTouchMoveHandler = this.touchMoveHandler.bind(this);
        this.bindTouchStartHandler = this.touchStartHandler.bind(this);
        this.bindOpeningEndedHeroHandler = this.openingEndedHeroHandler.bind(this);
        this.bindChangedHeroHandler = this.changedHeroHandler.bind(this);
        this.bindClickHeroSkipHandler = this.clickHeroSkipHandler.bind(this);

        this.bindMouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.bindClickLocalNavLeftBtHandler = this.clickLocalNavLeftBtHandler.bind(this);
        this.bindClickLocalNavRightBtHandler = this.clickLocalNavRightBtHandler.bind(this);


        document.addEventListener(this.mousewheelEvent, this.bindWheelHandler, {passive: false});
        document.addEventListener('touchstart', this.bindTouchStartHandler, {passive: false});
        document.addEventListener('touchmove', this.bindTouchMoveHandler, {passive: false});
        document.addEventListener('touchend', this.bindTouchEndHandler, {passive: false});

        this.pack.hero.addEventListener("openingEnded", this.bindOpeningEndedHeroHandler);
        this.pack.hero.addEventListener("changedScene", this.bindChangedHeroHandler);

        this.heroSkip.addEventListener("click", this.bindClickHeroSkipHandler);

        document.addEventListener('mousemove', this.bindMouseMoveHandler, {passive: false});

        this.localNavLeftBt.addEventListener("click", this.bindClickLocalNavLeftBtHandler);
        this.localNavRightBt.addEventListener("click", this.bindClickLocalNavRightBtHandler);

    }

    clickLocalNavLeftBtHandler(event){
        if (this.lockScroll) {
            if(!this.enabledScroll) return;
            if(this.currentHero === 1) return;
            this.pastHero = this.currentHero;
            this.currentHero--;
            let isPrev = true;
            this.changeScene(isPrev);
        }else{
            if(this.currentSection === -1){
                this.goBackToHero();
                this.pack.anchorScroll(0);
            }else if(this.currentSection === 0){
                this.currentSection--;
                this.changeScene(true);
                this.pack.anchorScroll(0);
            }else{
                this.currentSection--;
                this.pack.anchorScroll(this.pack.pageYOffset + this.sections[this.currentSection].getBoundingClientRect().top);
            }
        }
    }

    clickLocalNavRightBtHandler(event){
        if (this.lockScroll) {
            if(!this.enabledScroll) return;
            if(this.currentHero === this.HERO_SCENE_LEN) return;
            this.pastHero = this.currentHero;
            this.currentHero++;
            this.changeScene();
        }else{
            this.currentSection++;
            if(this.currentSection === this.sections.length) return;
            this.pack.anchorScroll(this.pack.pageYOffset + this.sections[this.currentSection].getBoundingClientRect().top);
        }
    }

    mouseMoveHandler(event){
        this.mouseX = event.clientX;
        this.mouseY = event.clientY;
    }

    clickHeroSkipHandler(event){
        this.pastHero = this.currentHero;
        this.currentHero = 8;
        this.pack.hero.skipScene();
        this.skipScene();
    }

    openingEndedHeroHandler(event){
        this.enabledScroll = true;
    }

    changedHeroHandler(event){
        this.enabledScroll = true;
        if(this.currentHero === this.HERO_SCENE_LEN) this.lockScroll = false;
    }

    wheelHandler(event) {
        if(this.pack.header.isMenuOpen) return;
        let delta = event.deltaY ? (event.deltaY) : event.wheelDelta ? event.wheelDelta : -(event.detail);

        let currentTime = Date.now();
        let currentDelta = Math.abs(delta);
        let isCancel = false;

        if(currentDelta <= this.prevWheelDelta){
           if(currentTime - this.prevWheelTime < 100 ) isCancel = true;
        }

        this.prevWheelTime = currentTime;
        this.prevWheelDelta = currentDelta;

        this.checkSceneChange(event, delta, isCancel);
    }

    touchStartHandler(event){
        if(this.pack.header.isMenuOpen) return;
        let touches = event.changedTouches[0];
        this.touchStartPoint = touches.clientY;
    }

    touchMoveHandler(event) {
        if(this.pack.header.isMenuOpen) return;
        if(this.isWaitingBackHeroScene8) {
            event.preventDefault();
            return;
        }

        if (this.lockScroll) event.preventDefault();
    }

    touchEndHandler(event){
        if(this.pack.header.isMenuOpen) return;
        if(!this.touchStartPoint) return;

        let touches = event.changedTouches[0];
        let y = touches.clientY;
        let delta = (y > this.touchStartPoint) ? -1 : 1;

        if(y === this.touchStartPoint) return;
        this.checkSceneChange(event, delta);
    }

    checkSceneChange(event, delta, isCancel){

        if(this.isWaitingBackHeroScene8) {
            event.preventDefault();
            return;
        }

        if (this.lockScroll) {
            if(isCancel){
                event.preventDefault();
                return;
            }

            window.scrollTo(0, 0);
            event.preventDefault();

            if(!this.enabledScroll) return;
            let isPrev;
            if(delta > 0){
                if(this.currentHero === this.HERO_SCENE_LEN) return;
                if(this.currentHero === 6){
                    let len = 6;
                    if(this.heroContents6Current < len){

                        this.heroContents6UL.classList.remove("show" + this.heroContents6Current);
                        this.heroContents6Current++;
                        this.heroContents6UL.classList.add("show" + this.heroContents6Current);
                        this.pack.hero.changeSubScene(6, this.heroContents6Current);
                        this.enabledScroll = false;
                        return;
                    }
                }

                if(this.currentHero === 7){
                    let len = 3;

                    if(this.heroContents7Current < len){
                        this.heroContents7UL.classList.remove("show" + this.heroContents7Current);
                        this.heroContents7Current++;
                        this.heroContents7UL.classList.add("show" + this.heroContents7Current);
                        this.pack.hero.changeSubScene(7, this.heroContents7Current);
                        this.enabledScroll = false;

                        return;
                    }
                }
                this.pastHero = this.currentHero;
                this.currentHero++;

            }else{
                if(this.currentHero === 1) return;

                if(this.currentHero === 6){
                    if(this.heroContents6Current > 1){

                        this.heroContents6UL.classList.remove("show" + this.heroContents6Current);
                        this.heroContents6Current--;
                        this.heroContents6UL.classList.add("show" + this.heroContents6Current);
                        this.pack.hero.changeSubScene(6, this.heroContents6Current);
                        this.enabledScroll = false;
                        return;
                    }
                }

                if(this.currentHero === 7){

                    if(this.heroContents7Current > 1){
                        this.heroContents7UL.classList.remove("show" + this.heroContents7Current);
                        this.heroContents7Current--;
                        this.heroContents7UL.classList.add("show" + this.heroContents7Current);
                        this.pack.hero.changeSubScene(7, this.heroContents7Current);
                        this.enabledScroll = false;

                        return;
                    }
                }

                this.pastHero = this.currentHero;
                this.currentHero--;
                isPrev = true;
            }

            this.changeScene(isPrev);
        }else{
            if(window.scrollY > 0) return;

            if(delta < 0){
                this.isWaitingBackHeroScene8 = true;
                TweenMax.delayedCall(1, ()=>{
                    this.isWaitingBackHeroScene8 = false;
                    this.goBackToHero();
                });
            }
        }
    }

    goBackToHero(){
        this.lockScroll = true;
        this.pastHero = this.currentHero;
        this.currentHero--;
        this.enabledScroll = false;
        this.pack.hero.setScene(this.currentHero, true);
        this.setScene(this.currentHero);
        this.changeLocalNavi(this.currentHero-1);
        this.changeNumerator(this.pack.addZero(String(this.currentHero-1)));
    }

    changeScene(isPrev){
        this.enabledScroll = false;
        this.pack.hero.setScene(this.currentHero, isPrev);
        this.setScene(this.currentHero);
        this.changeLocalNavi(this.currentHero-1);
        this.changeNumerator(this.pack.addZero(String(this.currentHero-1)));
    }

    resetHeroContents(contents_num){
        this["heroContents" + contents_num + "Current"] = 1;
        let ul = this["heroContents" + contents_num + "UL"];
        for( let i = 1, len = 6; i <= len; i++ ){
            ul.classList.remove("show" + i);
        }
    }

    start(){
        this.pack.hero.addHero(this.heroCanvasContainer);
        this.pack.hero.start();

        TweenMax.delayedCall(.7, ()=>{
            this.heroContents[0].classList.add("show");
        });

        TweenMax.delayedCall(2.3, ()=>{
            this.heroContents[0].classList.add("show");
            this.heroScroll.classList.add("show");
            this.heroCreateAccount.classList.add("show");
        });

        this.setValues();
        // this.setOurTeam();
        // this.loadOurTeamImages();
        this.setOurPartner();
        this.loadOurPartnerImages();
        this.pack.setDocuments(this.releaseNotesContainer);
    }

    setValues(){

        let items = this.pack.apiJSONList[0].items;

        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i].fields;

            let title = this.pack.getTitle(item);
            let description = this.pack.getDescription(item);
            let url = item.url;

            let imgAccessAPIPath;
            if(item.image) imgAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(item.image.sys.id);

            this.ourTeamList[i] = {title:title, description:description, url:url};
            this.ourTeamImgAccessAPIPathList[i] = imgAccessAPIPath;
        }

        items = this.pack.apiJSONList[1].items;
        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i].fields;
            let name = item.companyName;
            let description = this.pack.getDescription(item);
            let url = item.url;
            let imgAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(item.logoImage.sys.id);

            this.ourPartnerList[i] = {name:name, description:description, url:url};
            this.ourPartnerImgAccessAPIPathList[i] = imgAccessAPIPath;
        }
    }

    getOurTeamHTML(value){
        /* pug src
            li.item.carousel_item
                a.white(href="" target="_blank")
                    img(src="/assets/img/_post/top/team_member.jpg" alt="")
                    .name About Dummy Name
                    p.txt Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget porttitor urna.
         */

        let title = value.title;
        let description = value.description;
        let url = value.url;
        let addTxtClass = '';
        if(this.pack.LANG === 'ja') addTxtClass = ' font2';

        return `
            <li class="item carousel_item">
                <a class="white" href="${url}" target="_blank">
                    <img src="/assets/img/top/default.jpg" alt="${title}">
                    <div class="name">${title}</div>
                    <p class="txt${addTxtClass}">${description}</p>
                </a>
            </li>

        `;
    }

    getOurPartnerHTML(value){
        /*
            li.item
                a.white(href="" target="_blank")
                    div.img
                        img(src="/assets/img/_post/top/partner_logo.png" alt="")
                    p.txt Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget porttitor urna.
         */

        let name = value.name;
        let description = value.description;
        let url = value.url;
        let addTxtClass = '';
        if(this.pack.LANG === 'ja') addTxtClass = ' font2';

        return `
            <li class="item">
                <a class="white" href="${url}" target="_blank">
                    <div class="img">
                        <img src="" alt="${name}">
                    </div>
                    <p class="txt${addTxtClass}">${description}</p>
                </a>
            </li>
        `;
    }

    setOurTeam() {
        let src = "";
        for( let i = 0, len = this.ourTeamList.length; i < len; i++ ){
            let item = this.ourTeamList[i];
            let html = this.getOurTeamHTML(item);
            src += html;
        }

        this.ourTeamContainer.innerHTML = src;

        this.carousel = new Carousel(this.pack);
        this.setCarousel();
    }

    setOurPartner() {
        let src = "";
        for( let i = 0, len = this.ourPartnerList.length; i < len; i++ ){
            let item = this.ourPartnerList[i];
            let html = this.getOurPartnerHTML(item);
            src += html;
        }

        this.ourPartnerContainer.innerHTML = src;
    }

    loadOurTeamImages() {
        let urlList = this.ourTeamImgAccessAPIPathList;
        let promiseList = [];
        this.ourTeamImgPathList = [];

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this.ourTeamImgPathList[i]= res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                // trace("Our team img path loaded", this.ourTeamImgPathList);
                this.setOurTeamImgs();
            });
    }

    loadOurPartnerImages() {
        let urlList = this.ourPartnerImgAccessAPIPathList;
        let promiseList = [];
        this.ourPartnerImgPathList = [];

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this.ourPartnerImgPathList[i]= res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                // trace("Our partner img path loaded", this.ourPartnerImgPathList);
                this.setOurPartnerImgs();
            });
    }

    setOurTeamImgs() {
        let protocol = location.protocol;
        let imgs = document.querySelectorAll('main.top .section5 .items .item img');
        for( let i = 0, len = this.ourTeamImgPathList.length; i < len; i++ ){
            let imgURL = protocol + this.ourTeamImgPathList[i].fields.file.url;
            if(imgURL) imgs[i].src = imgURL;
        }
    }

    setOurPartnerImgs() {
        let protocol = location.protocol;
        let imgs = document.querySelectorAll('main.top .section4 .items .item img');
        for( let i = 0, len = this.ourPartnerImgPathList.length; i < len; i++ ){
            let imgURL = protocol + this.ourPartnerImgPathList[i].fields.file.url;
            imgs[i].src = imgURL;
        }
    }

    setScene(id){
        this.heroContents[this.pastHero-1].classList.remove("show");
        this.heroContents[this.currentHero-1].classList.add("show");

        if(id === 1) {
            this.heroCreateAccount.classList.remove("hide");
            this.pack.header.showHeader();
            this.heroSkip.classList.remove("show");
            this.heroScroll.classList.add("show");
            this.localNav.classList.remove("show");
        }else if(id === 2) {
            this.heroCreateAccount.classList.add("hide");
            this.pack.header.hideHeader();
            this.heroSkip.classList.add("show");
            this.heroScroll.classList.remove("show");
            this.localNav.classList.add("show");
        }
    }

    skipScene(){
        this.lockScroll = false;

        this.heroContents[this.pastHero-1].classList.remove("show");
        this.heroContents[this.currentHero-1].classList.add("show");
        this.heroCreateAccount.classList.add("hide");
        this.pack.header.hideHeader();
        this.heroSkip.classList.add("show");
        this.heroScroll.classList.remove("show");
        this.changeLocalNavi(this.currentHero-1);
        this.changeNumerator(this.pack.addZero(String(this.currentHero-1)));
        this.pack.anchorScroll(this.sh);
    }

    changeNumerator(value){
        this.localNavNumerator.innerText = value;
        this.localNavNumerator.classList.add("blink");
        TweenMax.delayedCall(.3, ()=>{
            this.localNavNumerator.classList.remove("blink");
        });
    }

    changeLocalNavi(num){
        if(num === 0) return;

        for( let i = 0, len = this.localNavLabels.length; i < len; i++ ){
            let item = this.localNavLabels[i];
            item.classList.remove("show");
        }

        this.localNavLabels[num-1].classList.add("show");
    }

    scrollHandler(){
        this.checkSections();
    }

    checkSections(){
        let current = -1;
        for( let i = 0, len = this.sections.length; i < len; i++ ){
            let item = this.sections[i];
            let top = item.getBoundingClientRect().top;
            if(!item.classList.contains("show")){
                if(top < 100) {
                    item.classList.add("show");
                }
            }

            if(top < 100) {
                current = i;
            }
        }

        this.currentSection = current;

        let num = current + 7;
        if(!this.localNavLabels[num].classList.contains("show")){
            num += 1;
            this.changeLocalNavi(num);
            this.changeNumerator(this.pack.addZero(String(num)));
        }

        if(this.pack.pageYOffset < this.shh) this.localNav.classList.remove("white");
        else this.localNav.classList.add("white");

        if(this.next.getBoundingClientRect().top <= this.sh) this.localNav.classList.remove("show");
        else this.localNav.classList.add("show");
    }

    enterframe(){

    }

    enterframeThinOut(){
        this.pack.hero.enterframeThinOut();
    }

    executeResize() {
        super.executeResize();

        this.heroSkip.style.top = this.sh + "px";

        if(this.sw >= this.pack.BP) this.heroCont3H3.style.top = "30.4444444444rem";
        else this.heroCont3H3.style.top = this.sh + "px";

        this.heroCont4H3.style.top = this.sh + "px";
        this.heroCont5H3.style.top = this.sh + "px";

        if(this.sw >= this.pack.BP) this.heroCont6H3.style.top = this.sh + "px";
        else this.heroCont6H3.style.top = 0 + "px";

        if(this.sw >= this.pack.BP) this.heroCont7H3.style.top = this.sh + "px";
        else this.heroCont7H3.style.top = 0 + "px";

        if(this.sw >= this.pack.BP) this.heroCont6Txt.style.top = this.sh + "px";
        else this.heroCont6Txt.style.top = 0 + "px";

        this.heroCont8H3.style.top = this.sh + "px";

        this.heroCont3Txt.style.top = this.sh + "px";
        this.heroCont4Txt.style.top = this.sh + "px";
        this.heroCont5Txt.style.top = this.sh + "px";
        this.heroCont6UL.style.top = this.sh + "px";
        this.heroCont7UL.style.top = this.sh + "px";
        this.heroCont8Txt.style.top = this.sh + "px";

        this.heroCont3Btn.style.top = this.sh + "px";
        this.heroCont4Btn.style.top = this.sh + "px";
        this.heroCont7Btn.style.top = this.sh + "px";
        this.heroCont8Btn.style.top = this.sh + "px";
    }
}
