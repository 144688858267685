import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { LinearFilter } from 'three/src/constants'

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import Stats from 'three/examples/jsm/libs/stats.module';
// import GUI from 'lil-gui';

//Lights
const cameraTargetX = 4.6, cameraTargetY = 2.7, cameraTargetZ = -9, cameraLookAtTargetX = -2.4, cameraLookAtTargetY = -.6, cameraLookAtTargetZ = 4.5,
    ambientIntensity = 0.7,
    ambientColor = 0xEBF0F5,
    directionalIntensity = 0.2,
    directionalColor = 0xd1dee0,
    directionalLightX = 0.5,
    directionalLightY = 0,
    directionalLightZ = -0.3,
    roadColor = 0xc7c7c7,
    roadEmissive = 0x505258,
    groundColor = 0xAAAAAA,
    defR = 0.4,
    defG = .791,
    defB = 1,
    accentR = 0.9607843137254902,
    accentG = 0.6196078431372549,
    accentB = 0.1803921568627451;

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';

        this.initEvents();

        // this.devicePixelRatio = window.devicePixelRatio || 1;
        // this.devicePixelRatio = Math.min(2, this.devicePixelRatio);

        this.initShader();
        this.initWebGL();
        this.load();

        if(this.enabledStats) this.setStats();
        if(this.enabledGUI) this.setGUI();
    }

    reset(){
        // super.reset();
        // this.setVars();
        this.initEvents();
    }

    destruct(){
        super.destruct();

        this.enabledUpdate = true;

        this.canvas.removeEventListener(this.pack.hasTouch ? 'touchmove' : 'mousemove', this.bindPointermoveHandler);

        this.camera.position.x = this.defCameraPosition.x;
        this.camera.position.y = this.defCameraPosition.y;
        this.camera.position.z = this.defCameraPosition.z;

        this.currentTargetPosition = new THREE.Vector3(0, 0, 0);
        this.currentLookatTargetPosition = new THREE.Vector3(0, 0, 0);

        this.currentScene = 1;

        this.currentWheelSpeed = this.wheelSpeed;

        TweenMax.to(this.canvas, 1, {opacity:1, ease:Quad.easeOut});

        this.container.remove(this.sensor);
        this.hideGeom(this.carWire);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficSign);

        this.hideGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.human);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.hideGeom(item);
        }

        this.mainRout.position.y = this.mainRoutDefY;

        this.container.remove(this.defineUseCase);
        this.container.remove(this.defineUseCaseAdd);

        this.resetSubScene6();
        this.resetSubScene7();
    }

    setVars(){
        super.setVars();

        this.enabledUpdate = true;
        this.enabledOrbitControls = false;
        this.enabledStats = false;
        this.enabledGUI = false;

        this.ORANGE_COLOR = {r:accentR, g:accentG, b:accentB};      //#F59E2E
        this.DEF_COLOR = {r:defR, g:defG, b:defB};

        this.GLB_ASSET_PATH = '/assets/glb/';
        this.GLB_MESHOPT_ASSET_PATH = this.GLB_ASSET_PATH + 'meshopt/';
        this.IMG_ASSET_PATH = '/assets/img/top/3d/';

        this.ENV_IMG_PATH = this.IMG_ASSET_PATH + 'env/env_white.jpg';

        let extension = (this.pack.ua.isSafari && !this.pack.ua.isSmartPhone) ? 'png' : 'webp';

        this.BAK_BUILDING_IMG_PATH = this.IMG_ASSET_PATH + 'bake/tex_building_col.' + extension;
        this.BAK_GROUND_IMG_PATH = this.IMG_ASSET_PATH + 'bake/tex_ground_col.' + extension;
        this.BAK_ROAD_IMG_PATH = this.IMG_ASSET_PATH + 'bake/tex_road_col.' + extension;
        this.BAK_SHADOW_IMG_PATH = this.IMG_ASSET_PATH + 'bake/bake_shadow_alpha_cc.' + extension;

        this.currentTargetPosition = new THREE.Vector3(0, 0, 0);
        this.currentLookatTargetPosition = new THREE.Vector3(0, 0, 0);

        this.currentCarPosVector;
        this.currentScene = 1;

        this.defRoadColor;
        this.defGroundColor;

        this.content;
        this.animeList = [];
        this.clock = new THREE.Clock();

        this.wheelSpeed = 15;
        this.currentWheelSpeed = this.wheelSpeed;
        this.wheelsDeg = 0;

        this.CAR_WIRE_CLONE_LEN = 10;
        this.HUMAN_CLONE_LEN = 6;
        this.CORN_CLONE_LEN = 8;
        this.TRAFFIC_LIGHT_CLONE_LEN = 3;
        this.carWireClones = [];
        this.humanClones = [];
        this.cornClones = [];
        this.trafficLightClones = [];

        this.OBJECT_OPACITY1 = .8;
        this.mainRoutDefY = 0;

        this.mouseStage = new THREE.Vector2();
    }

    setDom(){
        super.setDom();

        this.canvas = document.createElement("canvas");
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    addHero(container){
        container.appendChild(this.canvas);
    }

    removeHero(container){
        this.canvas.remove();
    }

    initEvents(){
        super.initEvents();

        this.bindPointermoveHandler = this.pointerMoveHandler.bind(this);
        //マウスオーバーとドラッグ処理
        this.canvas.addEventListener(this.pack.hasTouch ? 'touchmove' : 'mousemove', this.bindPointermoveHandler,{passive : false});
    }

    //for debug
    setGUI(){
        let sc = this;
        const gui = new GUI();
        const PROPS = {
            'cameraTargetX': cameraTargetX,
            'cameraTargetY': cameraTargetY,
            'cameraTargetZ': cameraTargetZ,
            'cameraLookAtTargetX': cameraLookAtTargetX,
            'cameraLookAtTargetY': cameraLookAtTargetY,
            'cameraLookAtTargetZ': cameraLookAtTargetZ,
            'ambientLight' : ambientColor,
            'ambientIntensity' : ambientIntensity,
            'directionalLight' : directionalColor,
            'directionalIntensity' : directionalIntensity,
            'directionalX' : directionalLightX,
            'directionalY' : directionalLightY,
            'directionalZ' : directionalLightZ,
            'roadColor' : roadColor,
            'roadEmissive' : roadEmissive,
            'groundColor' : groundColor,
            'defR' : defR,
            'defG' : defG,
            'defB' : defB,
            'accentR' : accentR,
            'accentG' : accentG,
            'accentB' : accentB,
        };

        gui.width = 350;
        gui.left = 0;
        gui.domElement.style.zIndex = 2000;
        gui.close();

        window.gv = ()=>{
            let tx = this.carCameraTarget.position.x;
            let ty = this.carCameraTarget.position.y;
            let tz = this.carCameraTarget.position.z;
            let ltx = this.cameraLookAtTarget.position.x;
            let lty = this.cameraLookAtTarget.position.y;
            let ltz = this.cameraLookAtTarget.position.z;
            let message = `cameraTargetX = ${tx}, cameraTargetY = ${ty}, cameraTargetZ = ${tz}, cameraLookAtTargetX = ${ltx}, cameraLookAtTargetY = ${lty}, cameraLookAtTargetZ = ${ltz}, 
            this.carCameraTarget.position.x = ${tx}, this.carCameraTarget.position.y = ${ty}, this.carCameraTarget.position.z = ${tz}, this.cameraLookAtTarget.position.x = ${ltx}, this.cameraLookAtTarget.position.y = ${lty}, this.cameraLookAtTarget.position.z = ${ltz};`;
            return message;
        };

        let maxCameraValue = 150;
        gui.add(PROPS, 'cameraTargetX', -maxCameraValue, maxCameraValue).onChange(() => {
            this.carCameraTarget.position.x = PROPS.cameraTargetX;
        });

        gui.add(PROPS, 'cameraTargetY', -maxCameraValue, maxCameraValue).onChange(() => {
            this.carCameraTarget.position.y = PROPS.cameraTargetY;
        });

        gui.add(PROPS, 'cameraTargetZ', -maxCameraValue, maxCameraValue).onChange(() => {
            this.carCameraTarget.position.z = PROPS.cameraTargetZ;
        });

        gui.add(PROPS, 'cameraLookAtTargetX', -maxCameraValue, maxCameraValue).onChange(() => {
            this.cameraLookAtTarget.position.x = PROPS.cameraLookAtTargetX;
        });

        gui.add(PROPS, 'cameraLookAtTargetY', -maxCameraValue, maxCameraValue).onChange(() => {
            this.cameraLookAtTarget.position.y = PROPS.cameraLookAtTargetY;
        });

        gui.add(PROPS, 'cameraLookAtTargetZ', -maxCameraValue, maxCameraValue).onChange(() => {
            this.cameraLookAtTarget.position.z = PROPS.cameraLookAtTargetZ;
        });

        gui.addColor(PROPS, 'ambientLight').onChange(function(){
            sc.ambientLight.color.set(PROPS.ambientLight);
        });

        gui.add(PROPS, 'ambientIntensity', 0, 1).onChange(function(){
            sc.ambientLight.intensity = PROPS.ambientIntensity;
        });

        gui.addColor(PROPS, 'directionalLight').onChange(function(){
            sc.directionalLight.color.set(PROPS.directionalLight);
        });

        gui.add(PROPS, 'directionalIntensity', 0, 1).onChange(function(){
            sc.directionalLight.intensity = PROPS.directionalIntensity;
        });

        gui.add(PROPS, 'directionalX', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.x = PROPS.directionalX;
        });

        gui.add(PROPS, 'directionalY', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.y = PROPS.directionalY;
        });

        gui.add(PROPS, 'directionalZ', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.z = PROPS.directionalZ;
        });

        gui.addColor(PROPS, 'roadColor').onChange(()=>{
            this.road.material.color.set(PROPS.roadColor);
        });

        gui.addColor(PROPS, 'roadEmissive').onChange(()=>{
            this.road.material.emissive.set(PROPS.roadEmissive);
        });

        gui.addColor(PROPS, 'groundColor').onChange(()=>{
            this.ground.material.color.set(PROPS.groundColor);
        });

        gui.add(PROPS, 'defR', 0, 1).onChange(() => {
            this.DEF_COLOR.r = PROPS.defR;
        });

        gui.add(PROPS, 'defG', 0, 1).onChange(() => {
            this.DEF_COLOR.g = PROPS.defG;
        });

        gui.add(PROPS, 'defB', 0, 1).onChange(() => {
            this.DEF_COLOR.b = PROPS.defB;
        });

        gui.add(PROPS, 'accentR', 0, 1).onChange(() => {
            this.ORANGE_COLOR.r = PROPS.accentR;
        });

        gui.add(PROPS, 'accentG', 0, 1).onChange(() => {
            this.ORANGE_COLOR.g = PROPS.accentG;
        });

        gui.add(PROPS, 'accentB', 0, 1).onChange(() => {
            this.ORANGE_COLOR.b = PROPS.accentB;
        });
    }

    setStats(){
        this.stats = Stats();
        document.body.appendChild(this.stats.dom)
    }

    pointerMoveHandler(event){
        let x, y;
        let w = this.sw;
        let h = this.sh;

        if(event.type.indexOf('touch') == 0) {
            let touches = event.changedTouches[0];
            x = touches.clientX;
            y = touches.clientY;
        }else{
            x = event.clientX;
            y = event.clientY;
        }

        this.mouseStage.x = x - (w / 2);
        this.mouseStage.y = -( y ) + (h / 2);
    }

    initShader() {
        this.vertexShaderSrc = `
        
        `;

        this.fragmentShaderSrc = `
        
        `;
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.width, this.height);
        this.renderer.autoClear = false;


        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0xE8FAFE );


        //Camera
        this.fieldOfView = 45;
        this.defCameraPosition = {};
        this.defCameraPosition.x = 192.3904571533203;
        this.defCameraPosition.y = 300;
        this.defCameraPosition.z = -135.74269104003906;
        this.camera = new THREE.PerspectiveCamera( this.fieldOfView, this.width / this.height, 0.01, 1000 );
        this.camera.position.x = this.defCameraPosition.x;
        this.camera.position.y = this.defCameraPosition.y;
        this.camera.position.z = this.defCameraPosition.z;

        this.ambientLight  = new THREE.AmbientLight(ambientColor, ambientIntensity);
        this.scene.add( this.ambientLight );

        this.directionalLight = new THREE.DirectionalLight(directionalColor, directionalIntensity);
        this.directionalLight.position.set(directionalLightX, directionalLightY, directionalLightZ).normalize();
        this.scene.add(this.directionalLight);


        //Controls
        if(this.enabledOrbitControls){
            this.controls = new OrbitControls(this.camera, this.renderer.domElement)
            // this.controls.enableDamping = true
        }
    }

    load(){
        this.container = new THREE.Object3D();
        this.scene.add(this.container);

        this.loadTexture(this.ENV_IMG_PATH, "texture_env")
            .then(this.loadTexture.bind(this, this.BAK_BUILDING_IMG_PATH, "texture_bake_building"))
            .then(this.loadTexture.bind(this, this.BAK_GROUND_IMG_PATH, "texture_bake_ground"))
            .then(this.loadTexture.bind(this, this.BAK_ROAD_IMG_PATH, "texture_bake_road"))
            .then(this.loadTexture.bind(this, this.BAK_SHADOW_IMG_PATH, "texture_bake_shadow"))
            .then(this.loadGeom.bind(this))
            .then(this.loadCar.bind(this))
            .then(this.loadCarWire.bind(this))
            .then(this.loadSensor.bind(this))
            .then(this.load6Layers04.bind(this))
            .then(this.load6Layers06.bind(this))
            .then(this.loadDefineUseCase.bind(this))
            .then(this.loadDefineUseCaseAdd.bind(this))
            .then(this.loadReferenceDesignInterior.bind(this))
            .then(this.loadReferenceDesignRoof.bind(this))
            .then(this.loadReferenceDesignSensor.bind(this))
            .then(() => {
                this.initMesh();
                let roadColor = this.road.material.color;
                let groundColor = this.ground.material.color;
                this.defRoadColor = {r:roadColor.r,g:roadColor.g,b:roadColor.b};
                this.defGroundColor = {r:groundColor.r,g:groundColor.g,b:groundColor.b};

                TweenMax.delayedCall(.5, ()=>{
                    this.loaded = true;
                    // trace("HERO 3D loaded");
                });
            });
    }


    loadTexture(path, name){
        let textureLoader = new THREE.TextureLoader();
        return new Promise((resolve) =>{
            textureLoader.load(path, (texture) => {
                texture.mapping = THREE.UVMapping;
                texture.flipY = false;
                texture.minFilter = LinearFilter;
                texture.magFilter = LinearFilter;
                this[name] = texture;

                return resolve();
            });
        });

    }

    loadGeom(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'geom_meshopt.glb',
                (gltf) => {
                    const scene = gltf.scene || gltf.scenes[0];
                    const clips = gltf.animations || [];


                    // trace("geom.glb", gltf);
                    this.setGeom(scene, clips);

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    showGeom(geom, opacity){
        const material = geom.material;
        // material.opacity = opacity || 1;
        material.needsUpdate = true;

        let o = opacity || 1;

        TweenMax.killTweensOf(material);
        TweenMax.to(material, 1, {opacity:o, ease:Quart.easeOut});
    }

    hideGeom(geom){
        const material = geom.material;
        material.transparent = true;
        material.alphaToCoverage = true;
        // material.opacity = 0;
        material.needsUpdate = true;
        TweenMax.killTweensOf(material);
        TweenMax.to(material, 1, {opacity:0, ease:Quart.easeOut});
    }

    setGeom(object, clips){
        this.container.add(object);
        this.content = object;
        let node;

        //car body
        this.car = this.content.getObjectByName("GRP_car");
        this.carTyres = [];
        this.car.traverse((child) => {
            if(child.isMesh) {
                child.material.transparent = true;
                child.material.alphaToCoverage = true;
                child.material.needsUpdate = true;
            }
            if(child.name.indexOf("GEOM_Tyre") > -1) this.carTyres.push(child);
        });

        this.carCameraTarget = new THREE.Object3D();
        this.car.add(this.carCameraTarget);

        this.cameraLookAtTarget = new THREE.Object3D();
        this.car.add(this.cameraLookAtTarget);

        //building
        this.building = this.content.getObjectByName("GEOM_Building");
        this.building.material.lightMap = this.texture_bake_building;
        this.building.material.needsUpdate = true;
        this.building.position.y -= 1;

        //ground
        this.ground = this.content.getObjectByName("GEOM_Ground");
        this.ground.material.color = new THREE.Color(groundColor);
        this.ground.material.lightMap = this.texture_bake_ground;
        this.ground.material.needsUpdate = true;
        this.ground.position.y -= 1;

        //road
        node = this.content.getObjectByName("GEOM_Road");
        this.road = node;
        node.material.color = new THREE.Color(roadColor);
        node.material.emissive = new THREE.Color(roadEmissive);
        node.material.lightMap = this.texture_bake_road;
        node.material.needsUpdate = true;

        //boxCar
        node = this.content.getObjectByName("GEOM_BoxCar");
        this.carWire = node;
        this.hideGeom(node);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let clone = this.carWire.clone();
            this.container.add(clone);
            this.hideGeom(clone);
            this.carWireClones.push(clone);
        }

        //carRoof
        node = this.content.getObjectByName("GEOM_CarRoof");
        this.carRoof = node;
        this.carRoofDefY = node.position.y;

        //carInterior
        node = this.content.getObjectByName("GEOM_CarInterior");
        this.carInterior = node;
        this.carInteriorDefY = node.position.y;

        //carSensor
        node = this.content.getObjectByName("GEOM_CarSensor");
        this.carSensor = node;
        this.carSensorDefY = node.position.y;

        //boxHuman
        node = this.content.getObjectByName("GEOM_BoxHuman");
        this.human = node;
        this.hideGeom(node);
        this.human.position.x = -164.7;
        this.human.position.z = -3;

        let rotationYList = [
            this.pack.d2r(160),
            this.pack.d2r(-210),
            this.pack.d2r(-190),
            this.pack.d2r(-130),
            this.pack.d2r(-140),
            this.pack.d2r(70)
        ];

        let positionList = [
            {x:-170, z:6.5},
            {x:-165.5, z:7.0},
            {x:-166.5, z:6.5},
            {x:-163.0, z:-5},
            {x:-164.5, z:-4.5},
            {x:-165.5, z:-4}
        ];

        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let clone = this.human.clone();
            let newMaterial = this.human.material.clone();
            this.container.add(clone);
            this.humanClones.push(clone);

            clone.position.x = positionList[i].x;
            clone.position.z = positionList[i].z;
            clone.rotation.y = rotationYList[i];

            newMaterial.color.r = 0;
            newMaterial.color.g = 0;
            newMaterial.color.b = 0;
            newMaterial.emissive.r = this.DEF_COLOR.r;
            newMaterial.emissive.g = this.DEF_COLOR.g;
            newMaterial.emissive.b = this.DEF_COLOR.b;
            newMaterial.transparent = true;
            newMaterial.alphaToCoverage = true;
            newMaterial.needsUpdate = true;
            clone.material = newMaterial;
            this.hideGeom(clone);
        }

        let newMaterial = this.human.material.clone();
        newMaterial.color.r = 0;
        newMaterial.color.g = 0;
        newMaterial.color.b = 0;
        newMaterial.emissive.r = this.DEF_COLOR.r;
        newMaterial.emissive.g = this.DEF_COLOR.g;
        newMaterial.emissive.b = this.DEF_COLOR.b;
        newMaterial.transparent = true;
        newMaterial.alphaToCoverage = true;
        newMaterial.needsUpdate = true;
        this.human.material = newMaterial;


        //trafficLight
        node = this.content.getObjectByName("GEOM_TrafficLight");
        this.trafficLight = node;
        this.trafficLight.position.x = -175;
        this.trafficLight.position.z = -9;
        this.trafficLight.rotation.y = this.pack.d2r(-20);
        this.hideGeom(node);

        rotationYList = [
            this.pack.d2r(160),
            this.pack.d2r(-110),
            this.pack.d2r(70)
        ];

        positionList = [
            {x:-170, z:6.5},
            {x:-165.5, z:-3},
            {x:-181.5, z:1}
        ];

        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let clone = this.trafficLight.clone();
            this.container.add(clone);
            this.hideGeom(clone);
            this.trafficLightClones.push(clone);
            clone.position.x = positionList[i].x;
            clone.position.z = positionList[i].z;
            clone.rotation.y = rotationYList[i];
        }


        //trafficSign
        node = this.content.getObjectByName("GEOM_TrafficSign");
        this.trafficSign = node;
        this.trafficSign.position.x = -165;
        this.trafficSign.position.z = -8;
        this.trafficSign.rotation.y = this.pack.d2r(-20);
        this.hideGeom(node);

        //corn
        node = this.content.getObjectByName("GEOM_Corn");
        this.trafficCorn = node;
        this.trafficCorn.position.x = -166;
        this.trafficCorn.position.z = -20;
        this.hideGeom(node);

        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let clone = this.trafficCorn.clone();
            this.hideGeom(clone);
            this.container.add(clone);
            this.cornClones.push(clone);
            clone.position.x = -166 - (i + 1) * .8;
            clone.position.z = -20 + (i + 1) * 2;
        }

        //mainRoute
        node = this.content.getObjectByName("GEOM_Mainroute");
        this.mainRout = node;
        this.mainRoutDefY = node.position.y;


        //shadow
        node = this.content.getObjectByName("GEOM_Shadow");
        this.carShadow = node;
        node.material.transparent = true;
        node.material.alphaToCoverage = true;
        node.material.map = this.texture_bake_shadow;
        node.material.needsUpdate = true;
        node.material.opacity = 1;
        node.position.y = 0.01;
    }

    loadCar(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 's07_car_anim_0615_meshopt_mid.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "car");
                    this.cameraTarget = gltf.scene.getObjectByName("SOCKET_CarAnim07");
                    // this.container.add(gltf.scene);
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadCarWire(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_otherloop_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "carWire");
                    this.carWireTarget = gltf.scene.getObjectByName("followpath_filtered_otherroute");

                    for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
                        let sceneClone = gltf.scene.clone();
                        let id = i + 1;
                        this.setAnimationClip(sceneClone, gltf.animations, "carWireClone" + id, false, (12 + Math.random() * .5) * id);
                        this["carWireTarget" + id] = sceneClone.getObjectByName("followpath_filtered_otherroute");
                    }

                    // this.container.add(gltf.scene);
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadSensor(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_sensorflat_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sensor");
                    this.sensorMeshMaterials = [];

                    let child = gltf.scene.getObjectByName("ANIM_SensorFlat");
                    const material = child.material;
                    material.transparent = true;
                    material.alphaToCoverage = true;
                    // material.opacity = 0.7;
                    material.needsUpdate = true;
                    this.sensorMeshMaterials.push(material);

                    this.sensor = gltf.scene;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    load6Layers04(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_sixlayer04_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sixLayers04");
                    // this.container.add(gltf.scene);
                    this.sixLayers04 = gltf.scene;
                    let mesh = gltf.scene.getObjectByName('ANIM_6layer04');
                    const material = mesh.material;
                    material.transparent = true;
                    material.alphaToCoverage = true;
                    material.opacity = .6;
                    material.needsUpdate = true;

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    load6Layers06(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_sixlayer06_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sixLayers06");
                    // this.container.add(gltf.scene);
                    this.sixLayers06 = gltf.scene;
                    this.sixLayers06.position.x = -.8;      //位置微調整

                    for( let i = 0, len = 4; i < len; i++ ){
                        let mesh = gltf.scene.getObjectByName('mesh_' + i);
                        const material = mesh.material;
                        material.transparent = true;
                        material.alphaToCoverage = true;
                        material.opacity = .6;
                        material.needsUpdate = true;
                    }

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadDefineUseCase(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_defineusecase_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "defineUseCase", true);
                    this.defineUseCase = gltf.scene;
                    let mesh = gltf.scene.getObjectByName('ANIM_line_defineusecase');
                    const material = mesh.material;
                    material.transparent = true;
                    material.alphaToCoverage = true;
                    material.opacity = .6;
                    material.needsUpdate = true;

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }


    loadDefineUseCaseAdd(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_defineusecase_add_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "defineUseCaseAdd", true);
                    this.defineUseCaseAdd = gltf.scene;
                    this.defineUseCaseAdd.position.y = 0.02;

                    let mesh = gltf.scene.getObjectByName('ANIM_line_defineusecase_additional');
                    const material = mesh.material;
                    material.transparent = true;
                    material.alphaToCoverage = true;
                    material.opacity = .6;
                    material.needsUpdate = true;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadReferenceDesignInterior(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_referencedesign_interior_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "referenceDesignInterior", true);
                    // this.container.add(gltf.scene);
                    this.referenceDesignInterior = gltf.scene;
                    this.referenceDesignInteriorTarget = gltf.scene.getObjectByName("__offset03");
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadReferenceDesignRoof(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_referencedesign_roof_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "referenceDesignRoof", true);
                    // this.container.add(gltf.scene);
                    this.referenceDesignRoof = gltf.scene;
                    this.referenceDesignRoofTarget = gltf.scene.getObjectByName("__offset02");

                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadReferenceDesignSensor(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                this.GLB_MESHOPT_ASSET_PATH + 'anim_referencedesign_sensor_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "referenceDesignSensor", true);
                    // this.container.add(gltf.scene);
                    this.referenceDesignSensor = gltf.scene;
                    this.referenceDesignSensorTarget = gltf.scene.getObjectByName("__offset01");
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    setAnimationClip(scene, animations, name, isOnce, offsetTime){
        let mixer = new THREE.AnimationMixer( scene );
        let anime = {};
        anime.mixer = mixer;
        anime.name = name;

        animations.forEach((clip, clipIndex) => {
            let action = mixer.clipAction(clip);
            if(isOnce) {
                //1回のみ再生
                action.setLoop(THREE.LoopOnce);
                action.clampWhenFinished = true;
            }

            anime.duration = clip.duration;
            anime.action = action;
        });

        if(!offsetTime) offsetTime = 0;
        anime.offsetTime = offsetTime;  //開始点指定
        this.animeList.push(anime);
    }

    initMesh(){
        // for debug
        // let geometry = new THREE.PlaneGeometry( 3, 3, 4 );
        // var material = new THREE.MeshBasicMaterial( {color: 0xff0000, opacity:.5, side: THREE.DoubleSide} );
        // this.plane = new THREE.Mesh( geometry, material );
        // this.scene.add( this.plane );

        let options = {
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter,
            magFilter: THREE.LinearFilter
        };

        //for Environment
        const renderTargetCube = new THREE.WebGLCubeRenderTarget( 1024, options ).fromEquirectangularTexture( this.renderer, this.texture_env );

        //add EnvironmentMap
        this.updateEnvironment(renderTargetCube.texture);
    }

    start(){
        window.scrollTo(0, 0);
        this.startScene1();

        let delay = 2.5;
        TweenMax.delayedCall(delay, () => {
            this.dispatchEvent("openingEnded");
        });
    }

    jumpCar(action, mixer){
        action.paused = false;
        action.reset().play();
        mixer.setTime(47.2);
        action.paused = true;
    }

    jumpCarWires(action, mixer, cloneCnt){
        action.paused = false;
        action.reset().play();
        let time = 78.25 + cloneCnt * 2.25;
        mixer.setTime(time);
        action.paused = true;
    }

    startDefineUseCase(action, mixer){
        this.container.add(this.defineUseCase);
        action.paused = false;
        action.reset().play();
        mixer.setTime(0);
    }

    startDefineUseCaseAdd(action, mixer){
        this.container.add(this.defineUseCaseAdd);
        action.paused = false;
        action.reset().play();
        mixer.setTime(0);
    }

    setScene(id, isPrev){
        let delay;
        this.currentScene = id;

        if(this.currentScene === 1){
            this.startScene1(isPrev);
            delay = 1.0;
        }else if(this.currentScene === 2){
            this.startScene2(isPrev);
            delay = 1.0;
        }else if(this.currentScene === 3){
            this.startScene3(isPrev);
            delay = 1.8;
        }else if(this.currentScene === 4){
            this.startScene4(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 5){
            this.startScene5(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 6){
            this.startScene6(isPrev);
            delay = 1.5;
        }else if(this.currentScene === 7){
            this.startScene7(isPrev);
            delay = 1.5;
        }else if(this.currentScene === 8){
            this.startScene8(isPrev);
            delay = 2.0;
        }

        TweenMax.delayedCall(delay, () => {
            this.dispatchEvent("changedScene");
        });
    }

    skipScene(){
        TweenMax.delayedCall(.5, ()=>{
            this.container.remove(this.sensor);
            this.hideGeom(this.carWire);
            for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
                let item = this.carWireClones[i];
                this.hideGeom(item);
            }

            this.hideGeom(this.trafficLight);
            for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                let item = this.trafficLightClones[i];
                this.hideGeom(item);
            }

            this.hideGeom(this.trafficSign);

            this.hideGeom(this.trafficCorn);
            for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                let item = this.cornClones[i];
                this.hideGeom(item);
            }

            this.hideGeom(this.human);
            for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
                let item = this.humanClones[i];
                this.hideGeom(item);
            }

            this.mainRout.position.y = this.mainRoutDefY;

            this.container.remove(this.defineUseCase);
            this.container.remove(this.defineUseCaseAdd);

            let cloneCnt = 0;
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "car"){
                    this.jumpCar(action, mixer);

                }else if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                    if(cloneCnt < 3){
                        this.jumpCarWires(action, mixer, cloneCnt);
                    }
                    cloneCnt++;
                }
            }

            this.resetSubScene6();
            this.resetSubScene7();
            this.startScene8();
        });
    }

    startScene1(isPrev){
        if(this.sw >= this.pack.BP){
            //Scene1
            /*
            cameraTargetX = 7.5, cameraTargetY = 2.7, cameraTargetZ = -18.6, cameraLookAtTargetX = -8.7, cameraLookAtTargetY = 1, cameraLookAtTargetZ = 4.8,
            this.carCameraTarget.position.x = 7.5, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -18.6, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 1, this.cameraLookAtTarget.position.z = 4.8;
             */

            this.carCameraTarget.position.x = 7.5, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -18.6, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 1, this.cameraLookAtTarget.position.z = 4.8;
        }else{
            //Scene1
            /*
            cameraTargetX = 5.7, cameraTargetY = 2.7, cameraTargetZ = -15.3, cameraLookAtTargetX = -7.5, cameraLookAtTargetY = 3, cameraLookAtTargetZ = 4.8,
            this.carCameraTarget.position.x = 5.7, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -15.3, this.cameraLookAtTarget.position.x = -7.5, this.cameraLookAtTarget.position.y = 3, this.cameraLookAtTarget.position.z = 4.8;
             */
            this.carCameraTarget.position.x = 5.7, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -15.3, this.cameraLookAtTarget.position.x = -7.5, this.cameraLookAtTarget.position.y = 3, this.cameraLookAtTarget.position.z = 4.8;
        }

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;

            if(anime.name === "car") {
                action.paused = false;
                action.play();
            }
        }
    }

    startScene2(isPrev){
        if(this.sw >= this.pack.BP){
            //Scene2
            /*
            cameraTargetX = 11.4, cameraTargetY = 1.2, cameraTargetZ = 7.2, cameraLookAtTargetX = 3.3, cameraLookAtTargetY = 6, cameraLookAtTargetZ = -14.4,
            this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 7.2, this.cameraLookAtTarget.position.x = 3.3, this.cameraLookAtTarget.position.y = 6, this.cameraLookAtTarget.position.z = -14.4;
             */
            this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 7.2, this.cameraLookAtTarget.position.x = 3.3, this.cameraLookAtTarget.position.y = 6, this.cameraLookAtTarget.position.z = -14.4;
        }else{
            /*
             cameraTargetX = 11.4, cameraTargetY = 1.2, cameraTargetZ = 13.2, cameraLookAtTargetX = -8.7, cameraLookAtTargetY = 15, cameraLookAtTargetZ = -29.4,
             this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 13.2, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 15, this.cameraLookAtTarget.position.z = -29.4;
              */
            this.carCameraTarget.position.x = 11.4, this.carCameraTarget.position.y = 1.2, this.carCameraTarget.position.z = 13.2, this.cameraLookAtTarget.position.x = -8.7, this.cameraLookAtTarget.position.y = 15, this.cameraLookAtTarget.position.z = -29.4;
        }


        this.container.remove(this.sensor);
        this.hideGeom(this.carWire);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }

        this.mainRout.position.y = this.mainRoutDefY;

        TweenMax.killTweensOf(this);
        this.currentWheelSpeed = this.wheelSpeed;
    }

    startScene3(isPrev){

        if(this.sw >= this.pack.BP){
            //Scene3
            this.carCameraTarget.position.x = -30.6, this.carCameraTarget.position.y = 150, this.carCameraTarget.position.z = 91.2, this.cameraLookAtTarget.position.x = -36, this.cameraLookAtTarget.position.y = -96, this.cameraLookAtTarget.position.z = -55.8;
        }else{
            //Scene3
            /**/
            this.carCameraTarget.position.x = -14.6, this.carCameraTarget.position.y = 100, this.carCameraTarget.position.z = 72, this.cameraLookAtTarget.position.x = -17.1, this.cameraLookAtTarget.position.y = -96, this.cameraLookAtTarget.position.z = -70.8;
        }

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car") {
                action.paused = false;
                action.play();
            }else if(anime.name === "sensor"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
            }
        }


        this.mainRout.position.y = 0.3;

        this.container.add(this.sensor);

        this.showGeom(this.carWire, this.OBJECT_OPACITY1);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item, this.OBJECT_OPACITY1);
        }

        TweenMax.killDelayedCallsTo(this.startDefineUseCase);
        TweenMax.killDelayedCallsTo(this.startDefineUseCaseAdd);
        this.container.remove(this.defineUseCase);
        this.container.remove(this.defineUseCaseAdd);

        this.hideGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficSign);

        this.hideGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.human);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.hideGeom(item);
        }

        let color = this.DEF_COLOR;
        let mesh = this.carWire;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.human;
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);


        mesh = this.trafficSign;
        emissive = mesh.material.emissive;
        this.changeMaterialEmissive(emissive, color);

        mesh = this.trafficLight;
        emissive = mesh.material.emissive;
        this.changeMaterialEmissive(emissive, color);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let mesh = this.trafficLightClones[i];
            emissive = mesh.material.emissive;
            this.changeMaterialEmissive(emissive, color);
        }

        mesh = this.trafficCorn;
        emissive = mesh.material.emissive;
        this.changeMaterialEmissive(emissive, color);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let mesh = this.cornClones[i];
            emissive = mesh.material.emissive;
            this.changeMaterialEmissive(emissive, color);
        }

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                action.paused = false;
                action.reset().play();
                mixer.setTime(anime.offsetTime);
            }
        }

        TweenMax.delayedCall(2.0, ()=>{
            //sensor
            for( let i = 0, len = this.animeList.length; i < len; i++ ){
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "sensor"){
                    mixer.time = 0;
                    action.paused = false;
                    action.reset().play();
                }
            }
        });
    }

    startScene4(isPrev){

        if(this.sw >= this.pack.BP){
            //Scene4
            this.carCameraTarget.position.x = -11.1, this.carCameraTarget.position.y = 7.5, this.carCameraTarget.position.z = -25.8, this.cameraLookAtTarget.position.x = 8.7, this.cameraLookAtTarget.position.y = -6.9, this.cameraLookAtTarget.position.z = 0.3;
        }else{
            //Scene4
            /*
                cameraTargetX = -11.1, cameraTargetY = 7.5, cameraTargetZ = -25.8, cameraLookAtTargetX = 2.1, cameraLookAtTargetY = -6.9, cameraLookAtTargetZ = 0.3,
                this.carCameraTarget.position.x = -11.1, this.carCameraTarget.position.y = 7.5, this.carCameraTarget.position.z = -25.8, this.cameraLookAtTarget.position.x = 2.1, this.cameraLookAtTarget.position.y = -6.9, this.cameraLookAtTarget.position.z = 0.3;
             */
            this.carCameraTarget.position.x = -11.1, this.carCameraTarget.position.y = 7.5, this.carCameraTarget.position.z = -25.8, this.cameraLookAtTarget.position.x = 2.1, this.cameraLookAtTarget.position.y = -6.9, this.cameraLookAtTarget.position.z = 0.3;
        }

        this.mainRout.position.y = this.mainRoutDefY;

        this.showGeom(this.human, this.OBJECT_OPACITY1);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.showGeom(item, this.OBJECT_OPACITY1);
        }

        this.showGeom(this.trafficLight, this.OBJECT_OPACITY1);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.showGeom(item, this.OBJECT_OPACITY1);
        }

        this.container.remove(this.sensor);
        this.showGeom(this.trafficSign, this.OBJECT_OPACITY1);
        this.showGeom(this.trafficCorn, this.OBJECT_OPACITY1);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.showGeom(item, this.OBJECT_OPACITY1);
        }

        let color = this.DEF_COLOR;
        let mesh = this.carWire;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.humanClones[0];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.humanClones[3];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.humanClones[4];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);
        mesh = this.humanClones[5];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        color = this.ORANGE_COLOR;
        mesh = this.human;
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color, 2.7);

        let cloneCnt = 0;
        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car"){
                this.jumpCar(action, mixer);

            }else if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                if(cloneCnt < 3){
                    this.jumpCarWires(action, mixer, cloneCnt);
                }else{
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
                cloneCnt++;
            }else if(anime.name === "defineUseCase"){
                TweenMax.killDelayedCallsTo(this.startDefineUseCase);
                action.paused = true;
                mixer.setTime(0);
                TweenMax.delayedCall(3.0, this.startDefineUseCase.bind(this, action, mixer));
            }else if(anime.name === "defineUseCaseAdd"){
                TweenMax.killDelayedCallsTo(this.startDefineUseCaseAdd);
                action.paused = true;
                mixer.setTime(0);
                TweenMax.delayedCall(3.2, this.startDefineUseCaseAdd.bind(this, action, mixer));
            }
        }
    }

    startScene5(isPrev){

        //Scene5
        this.carCameraTarget.position.x = 5.4, this.carCameraTarget.position.y = 3.3, this.carCameraTarget.position.z = 2.1, this.cameraLookAtTarget.position.x = -6.9, this.cameraLookAtTarget.position.y = 2.7, this.cameraLookAtTarget.position.z = -13.5;

        this.hideGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficSign);

        this.hideGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.hideGeom(item);
        }

        TweenMax.killDelayedCallsTo(this.startDefineUseCase);
        TweenMax.killDelayedCallsTo(this.startDefineUseCaseAdd);
        this.container.remove(this.defineUseCase);
        this.container.remove(this.defineUseCaseAdd);

        TweenMax.killTweensOf(this);
        this.currentWheelSpeed = this.wheelSpeed;

        let colorDelay = 2;
        let color = this.ORANGE_COLOR;
        let mesh = this.carWire;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color, colorDelay);

        color = this.DEF_COLOR;
        mesh = this.human;
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);
        TweenMax.delayedCall(colorDelay, ()=>{
            let color = this.ORANGE_COLOR;

            mesh = this.humanClones[0];
            emissive = mesh.material.emissive;
            this.tweenMaterialEmissive(emissive, color);

            mesh = this.humanClones[3];
            emissive = mesh.material.emissive;
            this.tweenMaterialEmissive(emissive, color);

            mesh = this.humanClones[4];
            emissive = mesh.material.emissive;
            this.tweenMaterialEmissive(emissive, color);
            mesh = this.humanClones[5];
            emissive = mesh.material.emissive;
            this.tweenMaterialEmissive(emissive, color);
        });

        this.resetSubScene6();
        this.pack.top.resetHeroContents(6);
    }

    startScene6(isPrev){
        if(this.sw >= this.pack.BP){
            //Scene6
            this.carCameraTarget.position.x = -2.7, this.carCameraTarget.position.y = 4.2, this.carCameraTarget.position.z = 33.9, this.cameraLookAtTarget.position.x = -8.4, this.cameraLookAtTarget.position.y = 10.5, this.cameraLookAtTarget.position.z = -27.6;
        }else{
            //Scene6
            /*
             */
            //Scene5
            this.carCameraTarget.position.x = -2.7, this.carCameraTarget.position.y = 2.1, this.carCameraTarget.position.z = 26, this.cameraLookAtTarget.position.x = 1.8, this.cameraLookAtTarget.position.y = -3.9, this.cameraLookAtTarget.position.z = -27.6;
        }

        let objectOpacity = this.OBJECT_OPACITY1;
        this.showGeom(this.trafficLight, objectOpacity);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.showGeom(item, objectOpacity);
        }

        this.showGeom(this.trafficSign, objectOpacity);

        this.showGeom(this.trafficCorn, objectOpacity);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.showGeom(item, objectOpacity);
        }

        this.showGeom(this.human, this.OBJECT_OPACITY1);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.showGeom(item, this.OBJECT_OPACITY1);
        }

        this.showGeom(this.carWire, this.OBJECT_OPACITY1);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item, this.OBJECT_OPACITY1);
        }

        let color = this.DEF_COLOR;
        let mesh = this.human;
        let emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.carWire;
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.humanClones[0];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.humanClones[3];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        mesh = this.humanClones[4];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);
        mesh = this.humanClones[5];
        emissive = mesh.material.emissive;
        this.tweenMaterialEmissive(emissive, color);

        let cloneCnt = 0;
        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0){
                if(cloneCnt < 3){
                    action.paused = false;
                    action.reset().play();
                    let time = 77.5 + cloneCnt * 2.25;
                    mixer.setTime(time);
                    action.paused = true;
                }else{
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
                cloneCnt++;
            }
        }

        this.resetSubScene7();
        this.pack.top.resetHeroContents(7);
    }

    startSubScene6(subScene){
        if(subScene === 1){
            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
            this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);
            for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                let item = this.trafficLightClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }
        }else if(subScene === 2){
            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);

            for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                let item = this.cornClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }

            color = this.ORANGE_COLOR;
            this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
            this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);
            for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                let item = this.trafficLightClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }
        }else if(subScene === 3){
            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
            this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);
            for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
                let item = this.trafficLightClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }

            color = this.ORANGE_COLOR;
            this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);

            for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                let item = this.cornClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }
            this.container.remove(this.sixLayers04);
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "sixLayers04"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
            }

        }else if(subScene === 4){
            TweenMax.to(this.ambientLight, 1, {intensity:ambientIntensity, ease:Quart.easeOut});

            let color = this.DEF_COLOR;
            this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);

            for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
                let item = this.cornClones[i];
                let emissive = item.material.emissive;
                this.tweenMaterialEmissive(emissive, color);
            }

            this.container.add(this.sixLayers04);
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;
                if(anime.name === "sixLayers04"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }
            }

        }else if(subScene === 5){
            this.container.remove(this.sixLayers04);
            this.container.remove(this.sixLayers06);
            TweenMax.to(this.ambientLight, 1, {intensity:1, ease:Quad.easeOut});
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;
                if(anime.name === "sixLayers04"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }else if(anime.name === "sixLayers06"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                    action.paused = true;
                }
            }
        }else if(subScene === 6){
            this.container.add(this.sixLayers06);
            TweenMax.to(this.ambientLight, 1, {intensity:ambientIntensity, ease:Quart.easeOut});
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;
                if(anime.name === "sixLayers06"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }
            }
        }
    }

    resetSubScene6(){
        let color = this.DEF_COLOR;
        this.tweenMaterialEmissive(this.trafficSign.material.emissive, color);
        this.tweenMaterialEmissive(this.trafficLight.material.emissive, color);

        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            let emissive = item.material.emissive;
            this.tweenMaterialEmissive(emissive, color);
        }

        this.tweenMaterialEmissive(this.trafficCorn.material.emissive, color);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            let emissive = item.material.emissive;
            this.tweenMaterialEmissive(emissive, color);
        }

        this.container.remove(this.sixLayers04);
        this.container.remove(this.sixLayers06);

        TweenMax.to(this.ambientLight, 1, {intensity:ambientIntensity, ease:Quart.easeOut});

        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "sixLayers04"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
                action.paused = true;
            }else if(anime.name === "sixLayers06"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
                action.paused = true;
            }
        }

    }

    startScene7(isPrev){
        this.enabledUpdate = true;
        TweenMax.to(this.canvas, 1, {opacity:1, ease:Quad.easeOut});

        this.resetSubScene6();
        this.pack.top.resetHeroContents(6);

        if(this.sw >= this.pack.BP){
            //Scene7
            this.carCameraTarget.position.x = 3.3, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -5.4, this.cameraLookAtTarget.position.x = -3, this.cameraLookAtTarget.position.y = -0.6, this.cameraLookAtTarget.position.z = 2.7;
        }else{
            //Scene7
            /*
            cameraTargetX = 4.6, cameraTargetY = 2.7, cameraTargetZ = -9, cameraLookAtTargetX = -2.4, cameraLookAtTargetY = -0.6, cameraLookAtTargetZ = 4.5,
            this.carCameraTarget.position.x = 4.6, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -9, this.cameraLookAtTarget.position.x = -2.4, this.cameraLookAtTarget.position.y = -0.6, this.cameraLookAtTarget.position.z = 4.5;
             */
            this.carCameraTarget.position.x = 4.6, this.carCameraTarget.position.y = 2.7, this.carCameraTarget.position.z = -9, this.cameraLookAtTarget.position.x = -2.4, this.cameraLookAtTarget.position.y = -0.6, this.cameraLookAtTarget.position.z = 4.5;
        }

        this.hideGeom(this.carWire);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficLight);
        for( let i = 0, len = this.TRAFFIC_LIGHT_CLONE_LEN; i < len; i++ ){
            let item = this.trafficLightClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.trafficSign);

        this.hideGeom(this.trafficCorn);
        for( let i = 0, len = this.CORN_CLONE_LEN; i < len; i++ ){
            let item = this.cornClones[i];
            this.hideGeom(item);
        }

        this.hideGeom(this.human);
        for( let i = 0, len = this.HUMAN_CLONE_LEN; i < len; i++ ){
            let item = this.humanClones[i];
            this.hideGeom(item);
        }
    }

    startSubScene7(subScene){
        if(subScene === 1){

        }else if(subScene === 2){
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "referenceDesignSensor"){
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }else if (anime.name === "referenceDesignRoof" || anime.name === "referenceDesignInterior") {
                    action.paused = true;
                    mixer.setTime(0);
                }
            }
        }else if(subScene === 3){
            for( let i = 0, len = this.animeList.length; i < len; i++ ) {
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if (anime.name === "referenceDesignRoof" || anime.name === "referenceDesignInterior") {
                    action.paused = false;
                    action.reset().play();
                    mixer.setTime(0);
                }
            }
        }
    }

    resetSubScene7(){
        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "referenceDesignSensor" || anime.name === "referenceDesignRoof" || anime.name === "referenceDesignInterior"){
                action.paused = false;
                action.reset().play();
                mixer.setTime(0);
                action.paused = true;
            }
        }
    }

    startScene8(isPrev){
        this.enabledUpdate = false;
        TweenMax.to(this.canvas, .5, {opacity:0, ease:Quad.easeOut});
        this.resetSubScene7();
        this.pack.top.resetHeroContents(7);

        this.carCameraTarget.position.x = -6;
        this.carCameraTarget.position.z = -20;

        if(this.sw >= this.pack.BP){
            this.carCameraTarget.position.y = 4.5;
            this.cameraLookAtTarget.position.x = -5.0;
            this.cameraLookAtTarget.position.y = -0.2;
            this.cameraLookAtTarget.position.z = -7.0;

        }else{
            this.carCameraTarget.position.y = 1.5;
            this.cameraLookAtTarget.position.x = 0;
            this.cameraLookAtTarget.position.y = -0.7;
            this.cameraLookAtTarget.position.z = -9.0;
        }
    }

    changeSubScene(scene, subScene){
        if(scene === 6) this.startSubScene6(subScene);
        else if(scene === 7) this.startSubScene7(subScene);

        TweenMax.delayedCall(.75, () => {
            this.dispatchEvent("changedScene");
        });
    }

    scrollHandler(){

    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    updateEnvironment (envMap) {
        // this.traverseMaterials(this.container, (material) => {
        this.traverseMaterials(this.car, (material) => {

            // trace(material);
            if (material.isMeshStandardMaterial || material.isGLTFSpecularGlossinessMaterial) {
                material.envMap = envMap;
                material.needsUpdate = true;
            }
        });
    }

    traverseMaterials (object, callback) {
        object.traverse((node) => {
            if (!node.isMesh) return;
            const materials = Array.isArray(node.material)
                ? node.material
                : [node.material];
            materials.forEach(callback);
        });
    }

    updateObject(node, position, target){
        node.position.x = position.x;
        node.position.y = position.y;
        node.position.z = position.z;
        node.rotation.x = target.rotation.x;
        node.rotation.y = target.rotation.y;
        node.rotation.z = target.rotation.z;
    }

    tweenMaterialEmissive(emissive, color, delay, dr, ease){
        delay = delay | 0;
        dr = dr | 1;
        ease = ease | Quart.easeOut;
        TweenMax.to(emissive, dr, {delay:delay, r:color.r, g:color.g, b:color.b, ease:ease});
    }

    changeMaterialEmissive(emissive, color){
        emissive.r = color.r;
        emissive.g = color.g;
        emissive.b = color.b;
    }

    enterframeThinOut(){
        if(this.pack.header.isMenuOpen) return;
        if(!this.enabledUpdate) return;
        if(this.enabledOrbitControls) this.controls.update();
        if(!this.loaded) return;

        let delta = this.clock.getDelta();

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let mixer = this.animeList[i].mixer;
            let name = this.animeList[i].name;

            if (this.currentScene === 3) {
                if(name === "sensor") {
                    mixer.update(delta);
                    let opacity = (1 - (mixer.time % anime.duration / anime.duration)) * .7;    //opacity70%

                    for (let i = 0, len = this.sensorMeshMaterials.length; i < len; i++) {
                        let m = this.sensorMeshMaterials[i];
                        m.opacity = opacity;
                    }
                }else if(name.indexOf("carWire") > -1) {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 4){
                if(name === "defineUseCase") {
                    mixer.update(delta);
                }else if(name === "defineUseCaseAdd") {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 6){
                if(name.indexOf("sixLayers") > -1) {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 7){
                if(name.indexOf("referenceDesign") > -1) {
                    mixer.update(delta);
                }
            }

            if(this.currentScene === 1 || this.currentScene === 2 || this.currentScene === 3){
                if(name === "car") {
                    mixer.update(delta);
                }
            }
        }

        //carアニメーション位置情報
        let carPosVector = new THREE.Vector3();
        carPosVector = this.currentCarPosVector = this.cameraTarget.getWorldPosition(carPosVector);

        //carWire位置アニメーション
        let carWireAdjustY = .8;
        let carWireVector = new THREE.Vector3();
        carWireVector = this.carWireTarget.getWorldPosition(carWireVector);
        carWireVector.y += carWireAdjustY;

        this.updateObject(this.carWire, carWireVector, this.carWireTarget);
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let id = i + 1;
            let target = this["carWireTarget" + id];
            let mesh = this.carWireClones[i];
            let carWireVector = new THREE.Vector3();

            carWireVector = target.getWorldPosition(carWireVector);
            carWireVector.y += carWireAdjustY;

            this.updateObject(mesh, carWireVector, target);
        }

        //carアニメーション
        this.updateObject(this.car, carPosVector, this.cameraTarget.parent);

        //carアニメーションに同期させる
        this.updateObject(this.referenceDesignInterior, carPosVector, this.cameraTarget.parent);
        this.updateObject(this.referenceDesignRoof, carPosVector, this.cameraTarget.parent);
        this.updateObject(this.referenceDesignSensor, carPosVector, this.cameraTarget.parent);
        this.updateObject(this.sensor, carPosVector, this.cameraTarget.parent);

        this.carInterior.position.y = this.carInteriorDefY + this.referenceDesignInteriorTarget.position.y;
        this.carRoof.position.y = this.carRoofDefY + this.referenceDesignRoofTarget.position.y;
        this.carSensor.position.y = this.carSensorDefY + this.referenceDesignSensorTarget.position.y;

        let cameraTargetPosVector = new THREE.Vector3();
        cameraTargetPosVector = this.carCameraTarget.getWorldPosition(cameraTargetPosVector);

        let targetPositionRightNow = new THREE.Vector3();
        let targetLookAt;

        //カメラのcarの追従
        if(!this.enabledOrbitControls){
            let cameraLookAtTargetPosVector = new THREE.Vector3();
            cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);

            targetPositionRightNow.x = cameraTargetPosVector.x;
            targetPositionRightNow.y = cameraTargetPosVector.y;
            targetPositionRightNow.z = cameraTargetPosVector.z;
            targetLookAt = cameraLookAtTargetPosVector;
        }

        let ease;
        let mouseX;
        let mouseY;

        if(this.currentScene === 1){
            mouseX = this.mouseStage.x / 150;
            mouseY = this.mouseStage.y / 180;
            ease = 10;
        }else if(this.currentScene === 2){
            mouseX = this.mouseStage.x / 150;
            mouseY = this.mouseStage.y / 450;
            ease = 10;
        }else if(this.currentScene === 3){
            mouseX = this.mouseStage.x / 50;
            mouseY = this.mouseStage.y / 50;
            ease = 30;
        }else if(this.currentScene === 4){
            mouseX = this.mouseStage.x / 870;
            mouseY = this.mouseStage.y / 870;
            ease = 15;
        }else if(this.currentScene === 5 || this.currentScene === 7){
            mouseX = this.mouseStage.x / 670;
            mouseY = this.mouseStage.y / 670;
            ease = 12;
        }else if(this.currentScene === 6){
            mouseX = this.mouseStage.x / 150;
            mouseY = this.mouseStage.y / 150;
            ease = 20;
        }else{
            mouseX = 0;
            mouseY = 0;
            ease = 20;
        }

        //for layout
        // mouseX = 0;
        // mouseY = 0;

        this.currentTargetPosition.x += (targetPositionRightNow.x - this.currentTargetPosition.x) / 3;
        this.currentTargetPosition.y += (targetPositionRightNow.y - this.currentTargetPosition.y) / 3;
        this.currentTargetPosition.z += (targetPositionRightNow.z - this.currentTargetPosition.z) / 3;

        this.camera.position.x += ((this.currentTargetPosition.x) - this.camera.position.x + mouseX) / ease;
        this.camera.position.y += ((this.currentTargetPosition.y) - this.camera.position.y + mouseY) / ease;
        this.camera.position.z += ((this.currentTargetPosition.z) - this.camera.position.z) / ease;

        this.currentLookatTargetPosition.x += (targetLookAt.x - this.currentLookatTargetPosition.x) / ease;
        this.currentLookatTargetPosition.y += (targetLookAt.y - this.currentLookatTargetPosition.y) / ease;
        this.currentLookatTargetPosition.z += (targetLookAt.z - this.currentLookatTargetPosition.z) / ease;

        this.camera.lookAt(this.currentLookatTargetPosition);


        //car wheels
        if(this.currentScene < 4){
            this.wheelsDeg += this.currentWheelSpeed;
            let wheelsRad = this.pack.d2r(this.wheelsDeg);
            for( let i = 0, len = this.carTyres.length; i < len; i++ ){
                let mesh = this.carTyres[i];
                if(i === 0 || i === 2) mesh.rotation.x = wheelsRad;
                else mesh.rotation.x = -wheelsRad;
            }
        }

        this.render();
        if(this.enabledStats) this.stats.update();
    }

    executeResize() {
        super.executeResize();

        if(!this.canvas) return;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        if(!this.camera) return;
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
    }
}
