import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import { OrthographicCamera } from 'three/src/cameras/OrthographicCamera';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        this.sw, this.sh;
        this.enterframeID;

        this.setVars();
        this.setDom();
        this.bindResizeHandler = this.resizeHandler.bind(this);
        this.hasTouch = ("ontouchstart" in window);
        // this.resizeEvent = this.hasTouch ? 'orientationchange' : 'resize';
        this.resizeEvent = 'resize';

        this.initEvents();
        this.initShader();
        this.initWebGL();
        this.initMesh();
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.enabled = false;
        this.scales = [];
        this.baseScale = 0;

        this.isTransitIn = false;
        this.isTransitOut = false;
    }

    setDom(){
        super.setDom();
        this.canvasContainer = document.querySelector('#transition');
        this.canvas = document.querySelector('#transition canvas');
    }

    initEvents(){
        super.initEvents();
    }

    initShader() {
        this.vertexShaderSrc = `
            attribute float scale;
            uniform float size;
            
            void main() {
                vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);                
                gl_PointSize = size * scale;
                gl_Position = projectionMatrix * mvPosition;
            }
        `;

        this.fragmentShaderSrc = `
            uniform sampler2D tex;
                        
            void main() {
                if(texture2D(tex, gl_PointCoord).a < .75) gl_FragColor = vec4(1., 1., 1., 0.);       //alphaTest
                else gl_FragColor = vec4(1., 1., 1., 1.) * texture2D(tex, gl_PointCoord);
            }
        `;
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : false,
            antialias : false
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = false;


        this.scene = new THREE.Scene();
        // this.scene.background = new THREE.Color( 0xE8FAFE );


        //Camera
        this.camera = new OrthographicCamera(-this.swh, this.swh, this.shh, -this.shh, 0.1, 10000);
        this.camera.position.set(0, 0, 10);
    }

    initMesh(){
        const texture = new THREE.TextureLoader().load( '/assets/img/common/kadomaru64.png' );

        this.setVariable();
        this.resizeMesh();
        this.setCntsForScale();

        this.material = new THREE.ShaderMaterial({
            uniforms:{
                size: {
                    type: 'f',
                    value: this.size
                },
                tex: {
                    type: 't',
                    value: texture
                }
            },
            vertexShader: this.vertexShaderSrc,
            fragmentShader: this.fragmentShaderSrc,
            transparent: true,
        });

        // 形状データを作成
        this.geometry = new THREE.BufferGeometry();
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));

        // 物体を作成
        const mesh = new THREE.Points(this.geometry, this.material);
        this.scene.add(mesh);
    }

    transitIn(callback, scope){
        this.isTransitIn = true;
        this.setCntsForScale();
        this.update();
        this.enabled = true;
        this.canvasContainer.classList.add("show");

        TweenMax.delayedCall(.1, ()=>{
            this.canvasContainer.classList.add("bigger");
        });

        TweenMax.delayedCall(1.4, ()=>{
            this.isTransitIn = false;
            if(scope) callback.call(scope);
            else callback();
        });
    }

    transitOut(){
        this.isTransitOut = true;
        this.canvasContainer.classList.remove("bigger");
        this.setCntsForScale(45);
        this.update();
        
        TweenMax.delayedCall(1.4, ()=>{
            this.isTransitOut = false;
            this.enabled = false;
            this.canvasContainer.classList.remove("show");
        });
    }

    update(){

        let addCnt = 25;
        let magnitude = 2.9;

        if(this.isTransitIn){
            for( let i = 0; i < this.cntsForScale.length; i++ ){
                this.cntsForScale[i] += addCnt;
                if(this.cntsForScale[i] > 45) this.cntsForScale[i] = 45;

                let cnt = this.cntsForScale[i];
                if(cnt < 0) cnt = 0;

                let rad = this.pack.d2r(cnt);
                let sin = this.baseScale + Math.sin(rad) * magnitude;

                this.scales[i] = sin;
            }
        }else if(this.isTransitOut){
            for( let i = 0; i < this.cntsForScale.length; i++ ){
                this.cntsForScale[i] += addCnt;

                let cnt = this.cntsForScale[i];
                if(cnt < 45) cnt = 45;
                if(cnt > 360) cnt = 360;

                let rad = this.pack.d2r(cnt);
                let sin = this.baseScale + Math.sin(rad) * magnitude;
                if(sin < 0) sin = 0;

                this.scales[i] = sin;
            }
        }

        this.geometry.setAttribute('scale', new THREE.Float32BufferAttribute(this.scales, 1));

        this.render();
    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    start(){

    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        if(this.enabled) this.update();
    }

    resizeMesh(){
        this.vertices = [];    //// 頂点情報を格納する配列
        let baseRatio = this.baseW / this.baseH;
        let stageRatio = this.sw / this.sh;
        let scale;
        if(stageRatio >= baseRatio) scale = this.sw / this.baseW;
        else scale = this.sh / this.baseH;

        this.size = this.baseSize * scale;
        this.gridSize = this.baseGridSize * scale;
        this.gridSizeH = this.gridSize / 2;

        let adjustX = (this.lenX / 2) * this.gridSize;
        let adjustY = (this.lenY / 2) * this.gridSize;
        for( let i = 0; i < this.lenX; i++ ){
            for( let j = 0; j < this.lenY; j++ ){
                const x = this.gridSize * i + this.gridSizeH - adjustX;
                const y = this.gridSize * j + this.gridSizeH - adjustY;
                const z = 0;
                this.vertices.push(x, y, z);
            }
        }
    }

    setVariable(){
        if(this.sw >= this.pack.BP){
            this.baseSize = 30;
            this.baseGridSize = 40;
            this.baseW = 1440;
            this.baseH = 789;
            this.lenX = 36;
            this.lenY = 20;
        }else{
            this.baseSize = 15;
            this.baseGridSize = 21;
            this.baseW = 375;
            this.baseH = 627;
            this.lenX = 18;
            this.lenY = 30;
        }
    }

    setCntsForScale(addNum){
        addNum = addNum || 0;

        this.cntsForScale = [];
        for( let i = 0; i < this.lenX; i++ ){
            for( let j = 0; j < this.lenY; j++ ){
                this.cntsForScale.push(-i * 10 - j * 10 + addNum);
            }
        }
    }

    executeResize() {
        super.executeResize();

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;
        this.camera.left = -this.swh;
        this.camera.right = this.swh;
        this.camera.top = this.shh;
        this.camera.bottom = -this.shh;

        this.camera.aspect = this.sw / this.sh;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);

        this.setVariable();
        this.resizeMesh();
        this.setCntsForScale();
        this.material.uniforms.size.value = this.size;
        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(this.vertices, 3));
    }
}
