import Platform from './Subpage';
import Carousel from "./Carousel";

export default class extends Platform{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.protocol = location.protocol;
        this.section1MediaAPIPathList = [];
        this.section2MediaAPIPathList = [];
        this.section3MediaAPIPathList = [];
    }

    setDom(){
        super.setDom();

        this.hero = document.querySelector('main.reference_design .hero');
        this.heroScroll = document.querySelector('main.subpage .hero .scroll');
        this.section1SubTit = document.querySelector('main.reference_design .sections .section1 h2 .sub_tit');
        this.section1Tit = document.querySelector('main.reference_design .sections .section1 h2 .tit');
        this.section1TitJa = document.querySelector('main.reference_design .sections .section1 h2 .tit_ja');
        this.section1ChunkContainer = document.querySelector('main.reference_design .sections .section1 .cont .right .chunk_container');
        this.section1Chunks = document.querySelectorAll('main.reference_design .section1 .cont .right .chunk');
        this.section1Imgs = document.querySelectorAll('main.reference_design .section1 .cont .left picture');

        this.section2 = document.querySelector('main.reference_design .section2');
        this.section2SubTit = document.querySelector('main.reference_design .sections .section2 h2 .sub_tit');
        this.section2Tit = document.querySelector('main.reference_design .sections .section2 h2 .tit');
        this.section2TitJa = document.querySelector('main.reference_design .sections .section2 h2 .tit_ja');
        this.section2Txt = document.querySelector('main.reference_design .sections .section2 .section_child > p.txt');
        this.section2Txts = document.querySelectorAll('main.reference_design .sections .section2 .cont .chunk .left .txt');
        this.section2Cont = document.querySelector('main.reference_design .sections .section2 .cont');
        this.section2Chunks = document.querySelectorAll('main.reference_design .sections .section2 .cont .chunk');
        this.section2StickyPcImgContainer = document.querySelector('main.reference_design .sections .section2 .sticky_pc_images');

        this.section3 = document.querySelector('main.reference_design .section3');
        this.section3SubTit = document.querySelector('main.reference_design .sections .section3 h2 .sub_tit');
        this.section3Tit = document.querySelector('main.reference_design .sections .section3 h2 .tit');
        this.section3TitJa = document.querySelector('main.reference_design .sections .section3 h2 .tit_ja');
        this.section3ChunkContainer = document.querySelector('main.reference_design .sections .section3 .cont');
        this.section3Txt = document.querySelector('main.reference_design .sections .section3 .section_child > p.txt');
        this.section3Cont = document.querySelector('main.reference_design .sections .section3 .cont');
        this.section3Chunks = document.querySelectorAll('main.reference_design .sections .section3 .cont .chunk');
        this.section3StickyPcImgContainer = document.querySelector('main.reference_design .sections .section3 .sticky_pc_images');

        this.pack.setSpansDelay('main.reference_design .hero h1 span.item');
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        super.start();

        this.carousel = new Carousel(this.pack);
        this.carousel2 = new Carousel(this.pack);

        this.setValues();
        this.sectionH3List = document.querySelectorAll('main.subpage .sections .section1 h3');
    }

    setCarousel(){
        this.carousel.setOnlySP();
        this.carousel.setTarget(this.section2);
        this.carousel.setMarginRightSP(true, this.pack.getSPVW(12));
        this.carousel.setAdjustMinXSP(true, 11.116666666666667);                   //5.25vw + 5.866666666666666vw(22px)
    }

    setCarousel2(){
        this.carousel2.setOnlySP();
        this.carousel2.setTarget(this.section3);
        this.carousel2.setMarginRightSP(true, this.pack.getSPVW(12));
        this.carousel2.setAdjustMinXSP(true, 11.116666666666667);                   //5.25vw + 5.866666666666666vw(22px)
    }

    setValues(){
        let items = this.pack.apiJSONList[3].items;
        let data = items[0].fields;
        this.setSection1Head(data);
        this.loadSection1Data(data);
        this.setSection2Head(data);
        this.setSection2(data);
        this.setSection3Head(data);
        this.loadSection3Data(data);
    }

    setSection1Head(data){
        //subTit
        let subTit = this.pack.getData(data, 'compositionOfReferenceDesignTitle');
        let subTitArray = subTit.split("<br>");
        let subTitSrc = this.getSubTitSrc(subTitArray);

        //tit
        let titEn = data['compositionOfReferenceDesignHeadlineEn'];
        let titJa = data['compositionOfReferenceDesignHeadlineJa'];
        let titArray = titEn.split("<br>");
        let titSrc = this.getTitSrc(titArray);

        this.section1SubTit.innerHTML = subTitSrc;
        this.section1Tit.innerHTML = titSrc;
        if(this.pack.LANG === 'ja') this.section1TitJa.innerHTML = titJa;

        this.pack.setSpansDelay('main.reference_design .sections .section1 h2 .tit span');
    }

    loadSection1Data(data){
        let articles = data.compositionOfReferenceDesignArtilecs;
        let urlList = [];
        let promiseList = [];
        let details = [];

        for( let i = 0, len = articles.length; i < len; i++ ){
            let sysId = articles[i].sys.id;
            let path = this.pack.getContentfulAPIPathWithSysID(sysId);
            urlList.push(path);
        }

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    // trace(i, res);
                    details[i] = res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                this.setSection1(details);
            });
    }

    setSection1(data){
        let src = '';
        for( let i = 0, len = data.length; i < len; i++ ){
            let item = data[i].items[0].fields;
            src += this.getSection1Chunk(item);
        }

        this.section1ChunkContainer.innerHTML = src;
        this.section1Chunks = document.querySelectorAll('main.reference_design .section1 .cont .right .chunk');
        this.loadSection1MediaPathes(this.section1MediaAPIPathList);
    }

    getSection1Chunk(data){
        let middleHeadline = this.pack.getData(data, "middleHeadline");
        let description = this.pack.getData(data, "description");
        this.section1MediaAPIPathList.push(this.pack.getContentfulAPIImgAccessPath(data.image.sys.id));

        return `
            <div class="chunk">
              <h3>${middleHeadline}</h3>
              <div class="txt">${description}</div>
              <div class="insert_img">
                <img src="" alt="" loading="lazy">
              </div>
            </div>
        `;
    }

    loadSection1MediaPathes(list){
        let urlList = list;
        let promiseList = [];
        let mediaPathList = [];
        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    // trace(i, res);
                    mediaPathList[i] = this.protocol + res.fields.file.url;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                this.setSection1Medias(mediaPathList);
            });
    }

    setSection1Medias(list){
        for( let i = 0, len = this.section1Chunks.length; i < len; i++ ){
            let item = this.section1Chunks[i];
            let img = item.querySelector('img');
            img.src = list[i];
        }
    }

    setSection2Head(data){
        //subTit
        let subTit = this.pack.getData(data, 'classificationOfOdDsTitle');
        let subTitArray = subTit.split("<br>");
        let subTitSrc = this.getSubTitSrc(subTitArray);

        //tit
        let titEn = data['classificationOfOdDsHeadlineEn'];
        let titJa = data['classificationOfOdDsHeadlineJa'];
        let titArray = titEn.split("<br>");
        let titSrc = this.getTitSrc(titArray);

        //description
        let description = this.pack.getData(data, 'classificationOfOdDsDescription');

        this.section2SubTit.innerHTML = subTitSrc;
        this.section2Tit.innerHTML = titSrc;
        this.section2Txt.innerHTML = description;
        if(this.pack.LANG === 'ja') this.section2TitJa.innerHTML = titJa;

        this.pack.setSpansDelay('main.reference_design .sections .section2 h2 .tit span');
    }

    setSection2(data){
        for( let i = 0, len = 3; i < len; i++ ){
            let id = i + 1;
            let pathID = data['classificationOfOdDsOdd' + id + 'Image'].sys.id;
            this.section2MediaAPIPathList.push(this.pack.getContentfulAPIImgAccessPath(pathID));
            this.section2Txts[i].innerHTML = this.pack.getData(data, 'classificationOfOdDsOdd' + id + 'Description');
        }

        this.loadSection2MediaPathes(this.section2MediaAPIPathList);
        this.setCarousel();
    }

    loadSection2MediaPathes(list){
        let urlList = list;
        let promiseList = [];
        let mediaPathList = [];
        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    // trace(i, res);
                    mediaPathList[i] = this.protocol + res.fields.file.url;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                this.setSection2Medias(mediaPathList);
            });
    }


    setSection2Medias(list){
        let stickyPCSrc = ``;
        for( let i = 0, len = this.section2Chunks.length; i < len; i++ ){
            let item = this.section2Chunks[i];
            let img = item.querySelector('img');
            let url = list[i];
            img.src = url;

            stickyPCSrc += `<picture><img src="${url}" alt=""></picture>`;
        }

        this.section2StickyPcImgContainer.innerHTML = stickyPCSrc;
        this.section2StickyPcImages = document.querySelectorAll('main.reference_design .sections .section2 .sticky_pc_images img');
    }

    setSection3Head(data){
        //subTit
        let subTit = this.pack.getData(data, 'eachReferenceDesignTitle');
        let subTitArray = subTit.split("<br>");
        let subTitSrc = this.getSubTitSrc(subTitArray);

        //tit
        // let tit = this.pack.getData(data, 'eachReferenceDesignHeadline');
        let titEn = data['eachReferenceDesignHeadlineEn'];
        let titJa = data['eachReferenceDesignHeadlineJa'];
        let titArray = titEn.split("<br>");
        let titSrc = this.getTitSrc(titArray);

        //description
        let description = this.pack.getData(data, 'eachReferenceDesignDescription');

        this.section3SubTit.innerHTML = subTitSrc;
        this.section3Tit.innerHTML = titSrc;
        this.section3Txt.innerHTML = description;
        if(this.pack.LANG === 'ja') this.section3TitJa.innerHTML = titJa;
        this.pack.setSpansDelay('main.reference_design .sections .section3 h2 .tit span');
    }

    loadSection3Data(data){
        let articles = data.eachReferenceDesignArticles;
        let urlList = [];
        let promiseList = [];
        let details = [];

        for( let i = 0, len = articles.length; i < len; i++ ){
            let sysId = articles[i].sys.id;
            let path = this.pack.getContentfulAPIPathWithSysID(sysId);
            urlList.push(path);
        }

        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    // trace(i, res);
                    details[i] = res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                this.setSection3(details);
            });
    }

    setSection3(data){
        let src = '';
        for( let i = 0, len = data.length; i < len; i++ ){
            let item = data[i].items[0].fields;
            src += this.getSection3Chunk(item);
        }

        this.section3ChunkContainer.innerHTML = src;
        this.section3Chunks = document.querySelectorAll('main.reference_design .sections .section3 .cont .chunk');
        this.loadSection3MediaPathes(this.section3MediaAPIPathList);
        this.setCarousel2();
    }

    getSection3Chunk(data){
        let middleHeadline = this.pack.getData(data, 'middleHeadline');
        let description = this.pack.getData(data, 'description');
        let linkLabel = this.pack.getData(data, 'linkLabel');
        let url = data.url;
        this.section3MediaAPIPathList.push(this.pack.getContentfulAPIImgAccessPath(data.image.sys.id));

        let btn = '';
        if(url){
            let linkLabelSrc = '';
            for( let i = 0, len = linkLabel.length; i < len; i++ ){
                let value = linkLabel.substr(i, 1);
                if(value === " ") linkLabelSrc += '<span class="item space">';
                else linkLabelSrc += '<span class="item">';
                linkLabelSrc += value;
                linkLabelSrc += '</span>';
            }

            btn = `
                <div class="btn">
                  <a href="${url}" target="_blank">
                    ${linkLabelSrc}
                  </a>
                </div>
            `;
        }

        return `
            <div class="chunk item carousel_item">
              <div class="left">
                <h3>${middleHeadline}</h3>
                <div class="txt">${description}</div>
                ${btn}
              </div>
              <div class="right">
                    <div class="img">
                      <img src="" alt="" loading="lazy">
                  </div>
              </div>
            </div>
        `;
    }

    loadSection3MediaPathes(list){
        let urlList = list;
        let promiseList = [];
        let mediaPathList = [];
        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    // trace(i, res);
                    mediaPathList[i] = this.protocol + res.fields.file.url;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                this.setSection3Medias(mediaPathList);
            });
    }

    setSection3Medias(list){
        let stickyPCSrc = ``;
        for( let i = 0, len = this.section3Chunks.length; i < len; i++ ){
            let item = this.section3Chunks[i];
            let img = item.querySelector('img');
            let url = list[i];
            img.src = url;

            stickyPCSrc += `<picture><img src="${url}" alt=""></picture>`;
        }

        this.section3StickyPcImgContainer.innerHTML = stickyPCSrc;
        this.section3StickyPcImages = document.querySelectorAll('main.reference_design .sections .section3 .sticky_pc_images img');
    }

    getSubTitSrc(subTitArray){
        let subTitSrc = '';
        for( let h = 0; h < subTitArray.length; h++ ){
            let subTit = subTitArray[h];
            subTitSrc += '<span class="span_chunk">';
            for( let i = 0, len = subTit.length; i < len; i++ ){
                let value = subTit.substr(i, 1);
                if(value === " ") subTitSrc += '<span class="item space">';
                else subTitSrc += '<span class="item">';
                subTitSrc += value;
                subTitSrc += '</span>';
            }
            subTitSrc += '</span>';
        }

        return subTitSrc;
    }

    getTitSrc(titArray){
        let titSrc = '';
        for( let h = 0; h < titArray.length; h++ ){
            let tit = titArray[h];
            if(h === 0) titSrc += '<span class="span_chunk chunk1">';
            else titSrc += '<span class="span_chunk">';
            for( let i = 0, len = tit.length; i < len; i++ ){
                let value = tit.substr(i, 1);
                if(value === " ") titSrc += '<span class="item space">';
                else titSrc += '<span class="item">';
                titSrc += value;
                titSrc += '</span>';
            }
            titSrc += '</span>';
        }

        return titSrc;
    }

    checkSection1(){
        let current = -1;
        for( let i = 0, len = this.section1Chunks.length; i < len; i++ ){
            let item = this.section1Chunks[i];
            if(item.getBoundingClientRect().top < this.sh - 200){
                current = i;
            }
        }

        if(current < 0) return;
        this.resizeHandler();

        for( let i = 0, len = this.section1Imgs.length; i < len; i++ ){
            let item = this.section1Imgs[i];
            let chunk = this.section1Imgs[i];
            item.classList.remove("show");
            chunk.classList.remove("clearify");
        }

        this.section1Imgs[current].classList.add("show");
        this.section1Chunks[current].classList.add("clearify");
    }

    scrollHandler(){
        super.scrollHandler();
        this.checkSection1();
    }


    enterframe(){

    }

    enterframeThinOut(){
        this.updateSection(2);
        this.updateSection(3);
    }

    updateSection(id){
        let cont = this['section' + id + 'Cont'];
        let imgs = this['section' + id + 'StickyPcImages'];
        let contY = cont.getBoundingClientRect().top - this.sw * .3;    //this.sw * .3でタイミング遅延の調整
        let contPer = 0;
        let checkPointY = this["section" + id + "StickyPcImgContainerY"];

        if(contY <= checkPointY){
            let h = cont.clientHeight;
            contPer = (checkPointY - contY) / h;

            if(contPer > 1) contPer = 1;
            let len = imgs.length;
            contPer *= len;

            for( let i = 0; i < len; i++ ){
                let img = imgs[i];
                let startPer = i;
                let per = contPer - startPer;

                if(per < 0 ) per = 0;
                else per *= 1.5;

                if(i === 0 && per < 1) per = 1;
                img.style.transform = 'scale(' + per + ')';
            }
        }
    }

    executeResize() {
        super.executeResize();

        this.hero.style.height = (this.sw >= this.pack.BP) ? this.sh * .87 + 'px' : this.sh + 'px';
        this.heroScroll.style.top = this.sh + 'px';
        this.section2StickyPcImgContainerY = this.shh - this.section2StickyPcImgContainer.clientHeight / 2;
        this.section3StickyPcImgContainerY = this.shh - this.section3StickyPcImgContainer.clientHeight / 2;

        this.section2StickyPcImgContainer.style.top = this.section2StickyPcImgContainerY + 'px';
        this.section3StickyPcImgContainer.style.top = this.section3StickyPcImgContainerY + 'px';
    }
}
